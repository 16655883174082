import React, { useState, Component } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Upload,
  Select,
  Modal,
  DatePicker,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ImgCrop from "antd-img-crop";
import moment from "moment";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const StyledDatePicker = styled(DatePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid lightgrey !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;

const dateFormat = "YYYY/MM/DD";

const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;

const onChange = (input, dateString) => {
  if (input && input._isAMomentObject) {
    console.log("Date:", input, dateString);
  } else if (input && input.target) {
    console.log("Change:", input.target.value);
  }
};

const Demo = () => {
  const [fileList, setFileList] = useState([
    //{
    //  uid: "-1",
    //  name: "image.png",
    //  status: "done",
    //  url: `${process.env.PUBLIC_URL}/images/b-img-13.jpg`,
    //},
  ]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <ImgCrop rotate>
      <Upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
      >
        {fileList.length < 5 && "+ Upload"}
      </Upload>
    </ImgCrop>
  );
};

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isSubmitting: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = async (values) => {
    try {
      if (values.dealerSettingKey === "Mahikeng") {
        const MSG = {
          // to: [values.branch,"leads@megatheron.co.za"],
          to: "leads@megatheron.co.za",
          from: "info@mtgroup.co.za",
          //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
          subject: "New car service request",
          text: " ",
          html: `
             <style type="text/css">  
        body, #bodyTable{background-color:white}
        #emailHeader{background-color:white}
        #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
        #emailFooter{background-color:#E1E1E1;}      
  </style>
  <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
  <center style="background-color:#E1E1E1;">
  <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
    <tr>
      <td align="center" valign="top" id="bodyCell">      
        <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
          <!-- HEADER ROW // -->
          <tr>
            <td align="center" valign="top">
              <!-- CENTERING TABLE // -->
              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                <tr>
                  <td align="center" valign="top">
                    <!-- FLEXIBLE CONTAINER // -->
                    <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                      <tr>
                        <td valign="top" width="500" class="flexibleContainerCell">
  
                          <!-- CONTENT TABLE // -->
                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                            <tr>
                         
                              <td align="right" valign="middle" class="flexibleContainerBox">
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                  <tr>
                                    <td align="left" class="textContent">
                                      <!-- CONTENT // -->

                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <!-- // FLEXIBLE CONTAINER -->
                  </td>
                </tr>
              </table>
              <!-- // CENTERING TABLE -->
            </td>
          </tr>
          <!-- // END -->
        </table>     
        <!-- EMAIL BODY // -->   
        <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
          <!-- MODULE ROW // -->
          <tr>
            <td align="center" valign="top">
              <!-- CENTERING TABLE // -->
              <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
                <tr>
                  <td align="center" valign="top">
                    <!-- FLEXIBLE CONTAINER // -->
                   
                    <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                      <tr>
                        <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                          <!-- CONTENT TABLE // -->
                        
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                 <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                  <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                              <td align="center" valign="top" class="textContent">
                                <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Car Service Request</h1>
                                <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new car service request from <br /> Mega Theron Group website</h2>
                              </td>
                            </tr>
                          </table>
                          <!-- // CONTENT TABLE -->
                        </td>
                      </tr>
                    </table>
                    <!-- // FLEXIBLE CONTAINER -->
                  </td>
                </tr>
              </table>
              <!-- // CENTERING TABLE -->
            </td>
          </tr>
          <!-- MODULE ROW // -->
          <tr mc:hideable>
            <td align="center" valign="top">
              <!-- CENTERING TABLE // -->
              <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                <tr>
                  <td align="center" valign="top">
                    <!-- FLEXIBLE CONTAINER // -->
                    <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                      <tr>
                        <td valign="top" width="500" class="flexibleContainerCell">
                          <!-- CONTENT TABLE // -->
                          <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                            <tr>
                              <td align="center" valign="top" class="flexibleContainerBox">
                                 <table border="0" cellspacing="0" cellpadding="0" >
              
                          <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                            <td class="role"><br />
    <strong> Scheduled Date </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.date.format('YYYY-MM-DDTHH:mm:ss.SSSZ')}<br />
    <strong> Brief Description </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details || "Service Booking"}<br />
    <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make}<br />
    <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.model || ""}<br />
    <strong> Registration </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.registration}<br />
    <strong> Odometer </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.odoMeter} KM<br />
    <strong> First Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.firstName}<br />
    <strong> Last Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.lastName}<br />
    <strong> Title </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.title || ""}<br />
    <strong> Contact Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.contactNumber}<br /><br />
                            </td>
                          </tr>
                          <table>
                              </td>
                            </tr>
                          </table>
                          <!-- // CONTENT TABLE -->
  
                        </td>
                      </tr>
                    </table>
                    <!-- // FLEXIBLE CONTAINER -->
                  </td>
                </tr>
              </table>
              <!-- // CENTERING TABLE -->
            </td>
          </tr>
          <!-- // MODULE ROW -->
  
  
          <!-- MODULE ROW // -->
         
  <table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
                <tr>
                  <td align="center" valign="top">
                  
                    <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                      <tr>
                        <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                      
                          <table border="0" cellpadding="30" cellspacing="0" width="100%">
                        
                      
                            <tr>
                              <td align="center" valign="top" class="textContent">
          
                                <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Mega Theron Group</h2>
                 
                              </td>
                            </tr>
                          </table>
                          <!-- // CONTENT TABLE -->
  
                        </td>
                      </tr>
                    </table>
                    <!-- // FLEXIBLE CONTAINER -->
                  </td>
                </tr>
              </table>
          `,
        };

        const emailResponse = await fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(MSG),
        });

        if (!emailResponse.ok) {
          console.log("handle error");
        } else {
          console.log("handle success");
          this.setState({
            visible: true,
            visibleservicecenter: false,
            submitDisabled: true,
            year: "",
            make: "",
            model: "",
            derivative: "",
            name: "",
            number: "",
            email: "",
            branch: "",
            date: "",
            details: "",
          });
        }
      } else {
        const response = await fetch('https://www.devvcsappcloud.com/mtgroup/api/requisition/bookservice', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            dealerSettingKey: values.dealerSettingKey,
            dtScheduled: values.date.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            briefDescription: values.details || "Service Booking",
            make: values.make,
            model: values.model || "",
            registration: values.registration,
            odoMeter: values.odoMeter,
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title || "",
            jobScheduleRef: values.jobScheduleRef || null,
            contactNumber: values.contactNumber,
          }),
        });

        const content = await response.json();

        if (content.result) {
          notification.success({
            message: 'Booking Successful',
            description: `Your booking number is ${content.bookingNumber}`,
            duration: 0, // Keeps the notification open until manually closed
            onClose: () => window.location.assign("/"), // Navigate to homepage on close
          });
        } else {
          notification.error({
            message: 'Booking Failed',
            description: content.message,
            duration: 0, // Keeps the notification open until manually closed
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while booking the service. Please try again.',
        duration: 0, // Keeps the notification open until manually closed
      });
    }
  };

  onFinish = (values) => {
    const MSG = {
      // to: [values.branch,"leads@megatheron.co.za"],
      to: "leads@megatheron.co.za",
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New car service request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Car Service Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new car service request from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.year
        }<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make
        }<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.model
        }<br />
                       
                          <strong> Derivative</strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.derivative
        }<br />
                          <strong> Details </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details
        }<br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name
        }<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number
        }<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email
        }<br />
                          <strong> Date </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.date.format(
          dateFormat
        )}<br />
        <strong> Branch </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.branch}
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        visibleservicecenter: false,
        submitDisabled: true,
        year: "",
        make: "",
        model: "",
        derivative: "",
        name: "",
        number: "",
        email: "",
        branch: "",
        date: "",
        details: "",
      });
    });
  };
  render() {
    return (
      <div>
        {window.isPhone != true ? (
          <div
            className="wrap-body-inner"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="row m-b-lg-50">
              <div className="col-sm-5 col-md-5 col-lg-5">
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <h1 className="f-weight-400 f-20">QUICK AND EFFICIENT</h1>
                  <h1 className="f-weight-600 f-50">
                    Book A Service <br />
                  </h1>
                  <h1
                    style={{ color: "red", textTransform: "none" }}
                    className="f-weight-400 f-50"
                  >
                    Servicing Made Easy
                  </h1>

                  <p>
                    Our expert team is ready to provide you with top-notch car
                    servicing. We ensure a hassle-free and smooth experience
                    from start to finish.
                  </p>
                  <a
                    href="#book-service"
                    className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                    style={{ textTransform: "none" }}
                  >
                    Book a Service
                  </a>
                  <Link
                    to="/ContactUs"
                    className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                    style={{
                      textTransform: "none",
                      marginLeft: "1em",
                      backgroundColor: "#201a20",
                    }}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5">
                <img
                  className="banner-img-home"
                  style={{
                    width: "60em",
                    marginTop: "-1.5em",
                    right: 0,
                    zIndex: 0,
                  }}
                  src={`${process.env.PUBLIC_URL}/images/service-banner.png`}
                  alt="logo"
                />
              </div>{" "}
            </div>
            <Form
              id="book-service"
              style={{ maxWidth: "80vw", margin: "auto" }}
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name="wrap"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                <div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div
                        className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                        style={{
                          boxShadow: "2px 2px 10px 2px lightgrey",
                          borderRadius: "1.5em",
                          backgroundColor: "white",
                        }}
                      >
                        <div className="m-b-lg-10">
                          <div className="heading-1 text-align-lg-center">
                            <h3>Book Vehicle Service</h3>
                          </div>
                          <p className="m-b-lg-20 text-align-lg-center">
                            Fill in the form below and one of our stock
                            specialists will get in touch
                          </p>
                        </div>

                        <div className="m-b-lg-20">
                          <div className="heading-1">
                            <h3>Car Details </h3>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="dealerSettingKey"

                                rules={[{ required: true, message: "Please select the a branch." }]}
                              >
                                <StyledSelect
                                  bordered={false}
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "0em",
                                    height: 50,
                                  }}
                                  placeholder="Branch"
                                >
                                  <OptGroup label="Klerksdorp">
                                    <Option key={0} value="MT003">
                                      Klerksdorp
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label="Potchefstroom">
                                    <Option key={1} value="MT004">
                                      Potchefstroom
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label="Mahikeng">
                                    <Option key={2} value="Mahikeng">
                                      Mahikeng
                                    </Option>
                                  </OptGroup>
                                </StyledSelect>
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="make"

                                rules={[{ required: true, message: "Please enter the vehicle make." }]}
                              >
                                <StyledInput placeholder="Make" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="model"

                                rules={[{ required: true, message: "Please enter the vehicle model." }]}
                              >
                                <StyledInput placeholder="Model" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="registration"

                                rules={[{ required: true, message: "Please enter the vehicle registration." }]}
                              >
                                <StyledInput placeholder="Registration" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="odoMeter"
                                rules={[{ required: true, message: "Please enter the odometer reading." }]}
                              >
                                <StyledInput
                                  placeholder="Odometer"
                                  type="number"
                                  suffix="KM"
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="date"
                                rules={[{ required: true, message: "Please select the date for service." }]}
                              >
                                <StyledDatePicker
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                  format="YYYY-MM-DD"
                                  disabledDate={(current) => {
                                    // Disable dates before today
                                    return current && current < moment().endOf('day');
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-12 col-lg-12">
                              <Form.Item
                                name="details"

                                rules={[{ required: true, message: "Please enter a brief description." }]}
                              >
                                <TextArea autoSize placeholder="Brief description of service details and requirements" />
                              </Form.Item>
                            </div>

                          </div>
                          <div className="m-b-lg-20">
                            <div className="heading-1">
                              <h3>Contact Details</h3>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="firstName"

                                  rules={[{ required: true, message: "Please enter your first name." }]}
                                >
                                  <StyledInput placeholder="First Name" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="lastName"

                                  rules={[{ required: true, message: "Please enter your last name." }]}
                                >
                                  <StyledInput placeholder="Last Name" />
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="title"
                                  rules={[{ required: false }]}
                                >
                                  <StyledSelect
                                    placeholder="Title (e.g. Mr, Ms)"
                                    bordered={false}
                                    style={{
                                      border: "1px solid black",
                                      borderRadius: "0em",
                                      height: 50,
                                    }}
                                  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Ms">Ms</Option>
                                    <Option value="Mrs">Mrs</Option>
                                    <Option value="Dr">Dr</Option>
                                    <Option value="Prof">Prof</Option>
                                    {/* Add more titles as needed */}
                                  </StyledSelect>
                                </Form.Item>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="contactNumber"
                                  rules={[
                                    { required: true, message: "Please enter your contact number." },
                                    { len: 10, message: "Contact number must be 10 digits." },
                                    { pattern: /^\d+$/, message: "Contact number must only contain digits." }
                                  ]}
                                >
                                  <StyledInput type="number"
                                    placeholder="Contact Number"
                                    maxLength={10}
                                  />
                                </Form.Item>
                              </div>

                              {/* <div className="col-sm-6 col-md-4 col-lg-3">
                                <Form.Item
                                  name="jobScheduleRef"

                                  rules={[{ required: false }]}
                                >
                                  <StyledInput placeholder="Job Schedule Reference (Optional)" />
                                </Form.Item>
                              </div> */}
                              <div className="col-lg-12 m-t-lg-12 text-center">
                                <Form.Item>
                                  <Button
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "55px",
                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "2em",
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.loading}
                                  >
                                    Submit
                                  </Button>
                                </Form.Item>
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "0em",
                                    fontSize: "13px",
                                    lineHeight: "1.5em",
                                  }}
                                >
                                  By submitting this form, you agree to our{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link style={{ color: "black" }} to="/terms">
                                      Terms of Use
                                    </Link>
                                  </span>{" "}
                                  and{" "}
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Link style={{ color: "black" }} to="/privacy-policy">
                                      Privacy Statement
                                    </Link>
                                  </span>
                                  .
                                </p>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
            <Modal
              visible={this.state.visible}
              style={{ textAlign: "center" }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Logo"
                style={{ width: "65%", margin: "auto" }}
              />
              <h3
                style={{
                  marginTop: "1em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Thank you for contacting
                <br />
                <span style={{ color: "rgb(214, 54, 41)" }}>
                  The Mega Theron Group.
                </span>{" "}
                <br />
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly.
                </span>{" "}
                <br />
              </h3>{" "}
              <br />
            </Modal>
          </div>
        ) : (
          <div
            className="wrap-body-inner"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="row m-b-lg-80 m-t-lg-40">
              <div>
                <div className="text-center">
                  <h1
                    className="f-weight-600 f-40"
                    style={{ marginBottom: "0em" }}
                  >
                    Book A Service
                  </h1>
                  <h1
                    style={{
                      color: "red",
                      textTransform: "none",
                      marginBottom: "0em",
                      marginTop: "-0.3em",
                      paddingTop: "0em",
                    }}
                    className="f-weight-400 f-40"
                  >
                    Servicing Made Easy
                  </h1>
                  <p>
                    Our expert team is ready to provide you with top-notch car
                    servicing. We ensure a hassle-free and smooth experience
                    from start to finish.
                  </p>
                  <a
                    href="#book-service-mobile"
                    className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                    style={{ textTransform: "none" }}
                  >
                    Book a Service
                  </a>{" "}
                  <Link
                    to="/ContactUs"
                    className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                    style={{
                      textTransform: "none",
                      marginLeft: "1em",
                      backgroundColor: "#201a20",
                    }}
                  >
                    Get in Touch
                  </Link>
                </div>
              </div>
            </div>
            <Form
              id="book-service-mobile"
              onFinish={this.handleSubmit}
              style={{ marginBottom: "-5em" }}
              initialValues={{
                remember: true,
              }}
              name="wrap"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <section className="m-t-lg-30 m-t-xs-0 m-b-lg-50">
                <div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15">
                        <div className="m-b-lg-10">
                          <div className="heading-1 text-align-lg-center">
                            <h3>Book Vehicle Service</h3>
                          </div>
                          <p className="m-b-lg-20 text-align-lg-center">
                            Fill in the form below and one of our stock
                            specialists will get in touch
                          </p>
                        </div>
                        <div className="m-b-lg-20">
                          <div className="heading-1 text-align-lg-center">
                            <h3>Car Details</h3>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="dealerSettingKey"

                                rules={[{ required: true, message: "Please select the a branch." }]}
                              >
                                <StyledSelect
                                  bordered={false}
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "0em",
                                    height: 50,
                                  }}
                                  placeholder="Branch"
                                >
                                  <OptGroup label="Klerksdorp">
                                    <Option key={0} value="MT003">
                                      Klerksdorp
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label="Potchefstroom">
                                    <Option key={1} value="MT004">
                                      Potchefstroom
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label="Mahikeng">
                                    <Option key={2} value="Mahikeng">
                                      Mahikeng
                                    </Option>
                                  </OptGroup>
                                </StyledSelect>
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="make"

                                rules={[{ required: true, message: "Please enter the vehicle make." }]}
                              >
                                <StyledInput placeholder="Make" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="model"

                                rules={[{ required: true, message: "Please enter the vehicle model." }]}
                              >
                                <StyledInput placeholder="Model" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="registration"

                                rules={[{ required: true, message: "Please enter the vehicle registration." }]}
                              >
                                <StyledInput placeholder="Registration" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="odoMeter"
                                rules={[{ required: true, message: "Please enter the odometer reading." }]}
                              >
                                <StyledInput
                                  placeholder="Odometer"
                                  type="number"
                                  suffix="KM"
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="date"
                                rules={[{ required: true, message: "Please select the date for service." }]}
                              >
                                <StyledDatePicker
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                  format="YYYY-MM-DD"
                                  disabledDate={(current) => {
                                    // Disable dates before today
                                    return current && current < moment().endOf('day');
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-12 col-lg-12">
                              <Form.Item
                                name="details"

                                rules={[{ required: true, message: "Please enter a brief description." }]}
                              >
                                <TextArea autoSize placeholder="Brief description of service details and requirements" />
                              </Form.Item>
                            </div>

                          </div>
                        </div>

                        <div className="m-b-lg-20">
                          <div className="heading-1 text-align-lg-center">
                            <h3>Contact Details</h3>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="firstName"

                                rules={[{ required: true, message: "Please enter your first name." }]}
                              >
                                <StyledInput placeholder="First Name" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="lastName"

                                rules={[{ required: true, message: "Please enter your last name." }]}
                              >
                                <StyledInput placeholder="Last Name" />
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="title"
                                rules={[{ required: false }]}
                              >
                                <StyledSelect
                                  placeholder="Title (e.g. Mr, Ms)"
                                  bordered={false}
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "0em",
                                    height: 50,
                                  }}
                                >
                                  <Option value="Mr">Mr</Option>
                                  <Option value="Ms">Ms</Option>
                                  <Option value="Mrs">Mrs</Option>
                                  <Option value="Dr">Dr</Option>
                                  <Option value="Prof">Prof</Option>
                                  {/* Add more titles as needed */}
                                </StyledSelect>
                              </Form.Item>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <Form.Item
                                name="contactNumber"
                                rules={[
                                  { required: true, message: "Please enter your contact number." },
                                  { len: 10, message: "Contact number must be 10 digits." },
                                  { pattern: /^\d+$/, message: "Contact number must only contain digits." }
                                ]}
                              >
                                <StyledInput type="number"
                                  placeholder="Contact Number"
                                  maxLength={10}
                                />
                              </Form.Item>
                            </div>

                            <div className="col-lg-12 m-t-lg-12 text-center">
                              <Form.Item>
                                <Button
                                  style={{
                                    border: "1px solid #F91700",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    borderRadius: "0.3em",
                                    backgroundColor: "#F91700",
                                    color: "white",
                                    height: "55px",
                                    paddingLeft: "3em",
                                    paddingRight: "3em",
                                    textShadow: "none",
                                    margin: "auto",
                                    marginTop: "2em",
                                  }}
                                  type="primary"
                                  htmlType="submit"
                                  loading={this.state.loading}
                                >
                                  Submit
                                </Button>
                              </Form.Item>
                              <p
                                style={{
                                  color: "black",
                                  marginTop: "0em",
                                  fontSize: "13px",
                                  lineHeight: "1.5em",
                                }}
                              >
                                By submitting this form, you agree to our{" "}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link style={{ color: "black" }} to="/terms">
                                    Terms of Use
                                  </Link>
                                </span>{" "}
                                and{" "}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link style={{ color: "black" }} to="/privacy-policy">
                                    Privacy Statement
                                  </Link>
                                </span>
                                .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
            <Modal
              visible={this.state.visible}
              style={{ textAlign: "center" }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Logo"
                style={{ width: "65%", margin: "auto" }}
              />
              <h3
                style={{
                  marginTop: "1em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Thank you for contacting
                <br />
                <span style={{ color: "rgb(214, 54, 41)" }}>
                  The Mega Theron Group.
                </span>{" "}
                <br />
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly.
                </span>{" "}
                <br />
              </h3>{" "}
              <br />
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default Service;
