import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  Carousel,
  Row,
  List,
  Typography,
  Button,
  Empty,
  Image,
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import styled from "styled-components";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
const { Option, OptGroup } = Select;

const { TextArea } = Input;
const selectBefore = (
  <Form.Item
    name="title"
    rules={[{ required: true, message: false }]}
    style={{ marginBottom: "0px" }}
  >
    <Select placeholder="Title" className="select-before">
      <Option value="Mr">Mr</Option>
      <Option value="Miss">Miss</Option>
    </Select>
  </Form.Item>
);
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledInput = styled(Input)`

  //padding-left: 1.2em !important;
  height: 50px !important;

    box-shadow:none !important;
  background-color: transparent !important;
  background-image: none;
  border: none;
  border-radius: 3px;
  color: black !important;
  box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  .ant-input-group-addon .ant-select {
    margin: 0em !important;
}
.ant-input-affix-wrapper {
  background-color: white !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px !important;
  color: white !important;
}
.ant-select-arrow {

  color: white!important;

}
.ant-input-group-addon {
  padding: 0px !important;
  color: white !important;
  background-color: rgb(228, 4, 1) !important;
}
.ant-input-affix-wrapper {

  height: 58px !important;
  border: none !important;}
  :placeholder {
    color: black!important;
  }
  :hover {
    border-color: black !important;
    border: none !important;
}
  :active{
    border-color: black !important;
   
  }
  :focus{
    border-color: black !important;
    
     box-shadow:none !important;
   
  }
  }
`;

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "top",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
      visible: false,
      thankYouVisible: false,
      selectedBranch: "",
      // klerksdorpMails: "leads@megatheron.co.za",
      // klerksdorpMail: "service@megatheron.co.za",
      potchefstroomMail: "potchservice@megatheron.co.za",
      potchefstroomMail1: "potchservice@megatheron.co.za",
      mahikengMail: "service1@megatheron.co.za",
      mahikengMail2: "service1@megatheron.co.za",
      mahikengMail3: "service1@megatheron.co.za",
      lichtenburgMail: "vanaarde@megatheron.co.za",
      klerksdorpMail: "leads@megatheron.co.za",
      selectedSpecial: null,
    };
    this.onFinish = this.onFinish.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    fetch(
      "https://www.devvcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }
  showModal = (pexUnit) => {
    this.setState({
      visible: true,
      selectedSpecial: pexUnit,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    // window.location.assign("./");
  };
  handleOkThankYou = (e) => {
    console.log(e);
    this.setState({
      thankYouVisible: false,
    });
    window.location.assign("./");
  };

  handleCancelThankYou = (e) => {
    console.log(e);
    this.setState({
      thankYouVisible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: "leads@megatheron.co.za",
      from: "info@mtgroup.co.za",
      subject: "New Specials Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Specials Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Specials Enquiry from <br /> Mega Theron Group website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.firstName}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.cellPhone}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Special Request </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.specialTitle}<br />
                          <strong> Details </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details}<br />
            
                          


                 
                      
          
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@MEGA</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: false,
        thankYouVisible: true,
        submitDisabled: true,
        title: null,
        firstName: null,
        surname: null,
        cellPhone: null,
        email: null,
        internalRef: null,
        specialTitle: null,
        details: null,
      });
    });
  };
  renderSearchBar() {
    const { dotPosition, jobDescription } = this.state;
    const { pexUnits } = this.props;

    const html = "${eventContent}";
    return (
      <div>
        <section className="block-sl">
          {" "}
          <div className="banner-item banner-2x banner-bg-download-center color-inher">
            {" "}
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="logo"
              style={{ width: "12em", marginBottom: "1em" }}
            />
            <h3 className="f-weight-300">
              <strong>AVAILABLE SPECIALS</strong>
              {/* <br />
              AVAILABLE SPECIALS. */}
            </h3>
            <p
              style={{
                margin: "auto",
                color: "grey",
                textAlign: "center",
                fontSize: 17,
              }}

            > Discover Exclusive Specials!</p>
          </div>
          <div
            // className="container-custom"
            style={{
              maxWidth: "80em",
              margin: "auto",
            }}
          >
            <div className="row">
              {this.state.pexUnits.length > 0 ? (
                this.state.pexUnits.map((pexUnit, index) => {
                  return (pexUnit.assignedBranch === "MT Group" &&
                    pexUnit.pageType === "SPECIALS") ||
                    (pexUnit.assignedBranch === "GWM (All)" &&
                      pexUnit.pageType === "SPECIALS")
                    ||
                    (pexUnit.assignedBranch === "Kia Mahikeng" &&
                      pexUnit.pageType === "SPECIALS")
                    ||
                    (pexUnit.assignedBranch === "Haval (All)" &&
                      pexUnit.pageType === "SPECIALS") ? (
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <List
                        style={{
                          backgroundImage: window.isPhone
                            ? "url(" +
                            `${process.env.PUBLIC_URL}/images/tyre-track-back.png` +
                            ")"
                            : "url(" +
                            `${process.env.PUBLIC_URL}/images/tyre-track-back-1.png` +
                            ")",
                          backgroundSize: window.isPhone ? "33em" : "76em",
                          backgroundRepeat: "no-repeat",
                          margin: "auto",
                          marginTop: "2em",
                          maxWidth: "70em",
                          boxShadow: "1px 1px 15px 1px #888888",
                          borderRadius: "2px",
                          paddingRight: window.isPhone ? "" : "1em",
                          paddingTop: window.isPhone ? "0em" : "0em",
                          paddingBottom: "0",
                        }}
                      >
                        {" "}
                        <List.Item>
                          <List.Item.Meta
                            // avatar={<Avatar src={item.avatar} />}
                            style={{ textAlign: "center" }}
                            title={
                              <span
                                style={{
                                  // flexWrap: "wrap-reverse",
                                  alignContent: "space-between",
                                  display: "inline-flex",
                                  fontSize: window.isPhone ? 22 : 22,
                                  lineHeight: "1em",
                                  paddingLeft: "0.1em",
                                  paddingRight: "0.1em",
                                  height: "2em",
                                  overflow: "hidden"
                                }}
                              >
                                {pexUnit.specialTitle}{" "}
                              </span>
                            }
                            description={
                              <span>
                                {/* <span
                                  style={{
                                    fontSize: window.isPhone ? 25 : 23,
                                  }}
                                >
                                  Price:{" "}
                                </span>
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: window.isPhone ? 25 : 23,
                                  }}
                                >
                                  {pexUnit.specialPrice}
                                </span>
                                <br />
                                Internal Ref:
                                <span style={{ color: "red" }}>
                                  {pexUnit.internalRef}
                                </span> */}
                                {/* <br />
                                <br /> */}
                                <p
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                    fontSize: 13,
                                    paddingRight: window.isPhone
                                      ? "1em"
                                      : "2em",
                                    paddingLeft: window.isPhone ? "1em" : "2em",
                                    height: "6em",
                                    alignContent: "center",
                                    textOverflow: "ellipsis",
                                    // whiteSpace: "nowrap",
                                    // width: "19em",
                                    overflow: "hidden",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: pexUnit.specialDescription,
                                  }}
                                />
                                <Button
                                  onClick={() => this.showModal(pexUnit)}
                                  style={{
                                    fontFamily: "Mulish",
                                    border: "1px solid #F91700",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    borderRadius: "3px",
                                    backgroundColor: "#F91700",
                                    color: "white",
                                    height: "40px",
                                    paddingLeft: "3em",
                                    paddingRight: "3em",
                                    textShadow: "none",
                                    margin: "auto",
                                    marginTop: "1em",
                                    marginBottom: "2em",
                                  }}
                                  type="primary"
                                  htmlType="submit"
                                >
                                  Enquire
                                </Button>{" "}
                                {window.isPhone ? (
                                  <Image
                                    style={{
                                      width: window.isPhone ? "22em" : "33em",
                                      height: window.isPhone ? "22em" : "33em",
                                      objectFit: "cover",
                                      // marginTop: window.isPhone ? "5em" : "",
                                    }}
                                    src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnit.branchSpecialImage}`}
                                    alt="logo"
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            }
                          />
                          {window.isPhone ? (
                            ""
                          ) : (
                            <span>
                              <Image
                                style={{
                                  width: "19.5em",
                                  height: "19.5em",
                                  objectFit: "cover",
                                }}
                                src={`https://www.devvcsappcloud.com/mtgroup/api/images/${pexUnit.branchSpecialImage}`}
                                // src={pexUnit.branchSpecialImage}
                                alt="logo"
                              />
                            </span>
                          )}
                          {/* <div
                            style={{
                              position: window.isPhone ? "" : "absolute",
                              textAlign: window.isPhone ? "center" : "",
                              color: "lightgrey",
                              marginTop: window.isPhone ? "1em" : "",
                              bottom: 0,
                              left: "4.1em",
                            }}
                          >
                            Published: {pexUnit.datePosted}
                            <br />
                          </div> */}
                        </List.Item>
                      </List>
                    </div>
                  ) : null;
                })
              ) : (
                <Empty description={<span>Loading Specials...</span>} />
              )}
            </div>
            <Modal
              visible={this.state.thankYouVisible}
              style={{ textAlign: "center" }}
              onOk={this.handleOkThankYou}
              onCancel={this.handleCancelThankYou}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Logo"
                style={{ width: "65%", margin: "auto", marginTop: "2em" }}
              />
              <br />
              <br />
              <br />
              <span
                style={{
                  marginTop: "1em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Thank you for contacting GWM.
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly.
                </span>{" "}
                <br />
              </span>{" "}
              <br />
            </Modal>
            <Modal
              // key={pexUnit._id}
              // id={pexUnit._id}
              visible={this.state.visible}
              style={{ textAlign: "center", top: 20, }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              {this.state.selectedSpecial && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {" "}
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="logo"
                      style={{ width: "12em", marginTop: "2em" }}
                    />
                    <h3
                      className="f-weight-700"
                      style={{
                        paddingTop: "1em",
                      }}
                    >
                      <strong>Discover More Details</strong>
                    </h3>
                    <div className="contact-form p-lg-10 p-xs-5  bg1-gray-2">
                      <Form
                        onFinish={this.handleSubmit}
                        initialValues={{
                          remember: true,
                        }}
                        name="wrap"
                        labelCol={{ flex: "110px" }}
                        labelAlign="left"
                        labelWrap
                        wrapperCol={{ flex: 1 }}
                        colon={false}
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: false }]}
                            hasFeedback
                          >
                            <StyledInput
                              // addonBefore={selectBefore}
                              placeholder="Name"
                            />
                          </Form.Item>
                        </div>
                        {/* <div className="col-sm-12 col-md-6 col-lg-6">
                          <Form.Item
                            name="surname"
                            rules={[{ required: true, message: false }]}
                            hasFeedback
                          >
                            <StyledInput placeholder="Surname" />
                          </Form.Item>
                        </div> */}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <Form.Item
                            name="cellPhone"
                            rules={[{ required: false, message: false }]}
                            hasFeedback
                          >
                            <StyledInput placeholder="Number" />
                          </Form.Item>
                        </div>
                        <div
                          className="col-sm-12 col-md-12 col-lg-12"
                          style={{ display: "none" }}
                        >
                          <Form.Item
                            name="specialTitle"
                            rules={[{ required: false, message: false }]}
                            hasFeedback
                            initialValue={
                              this.state.selectedSpecial.specialTitle
                            }
                          // style={{display:"none"}}
                          >
                            <StyledInput
                              defaultValue={
                                this.state.selectedSpecial.specialTitle
                              }
                              value={this.state.selectedSpecial.specialTitle}
                            />
                          </Form.Item>
                        </div>
                        {/* <div
                          className="col-sm-12 col-md-12 col-lg-12"
                          style={{ display: "none" }}
                        >
                          <Form.Item
                            name="internalRef"
                            rules={[{ required: false, message: false }]}
                            hasFeedback
                            initialValue={
                              this.state.selectedSpecial.internalRef
                            }
                          // style={{display:"none"}}
                          >
                            <StyledInput
                              defaultValue={
                                this.state.selectedSpecial.internalRef
                              }
                              value={this.state.selectedSpecial.internalRef}
                            />
                          </Form.Item>
                        </div> */}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: false,
                                type: "email",
                              },
                            ]}
                            hasFeedback
                          >
                            <StyledInput placeholder="Email" />
                          </Form.Item>
                        </div>

                        {/* <div className="col-sm-12 col-md-12 col-lg-12">
                          <Form.Item
                            name="branch"
                            rules={[{ required: true, message: false }]}
                            hasFeedback
                            initialValue={this.state.klerksdorpMail}
                          >
                            <StyledSelect
                              bordered={false}
                              style={{
                                border: "1px solid black",
                                borderRadius: "0em",
                                paddingTop: "0.4em",
                                color: "black",
                                textAlign: "left",
                              }}
                              placeholder="Branch"
                              defaultValue={this.state.klerksdorpMail}
                              //onChange={this.setCarEngineCapacity}
                            >
                              <OptGroup label="Klerksdorp">
                                <Option
                                  key={1}
                                  value={this.state.klerksdorpMail}
                                >
                                  GWM (Klerksdorp)
                                </Option>
                              </OptGroup>
                              <OptGroup label="Potchefstroom">
                          <Option key={1} value={this.state.potchefstroomMail1}>
                            GWM (Potchefstroom)
                          </Option>
                        </OptGroup>
                        <OptGroup label="Mahikeng">
                          <Option key={2} value={this.state.mahikengMail3}>
                            GWM (Mahikeng)
                          </Option>
                        </OptGroup>
                        <OptGroup label="Lichtenburg">
                          <Option key={1} value={this.state.lichtenburgMail}>
                            GWM (Lichtenburg)
                          </Option>
                        </OptGroup>
                            </StyledSelect>
                          </Form.Item>
                        </div> */}
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {/* <Form.Item
                            name="details"
                            rules={[{ required: false, message: false }]}
                            hasFeedback
                          >
                            <StyledInput
                              placeholder="Comments"
                              showCount
                              maxLength={100}
                              style={{
                                height: "120px",
                              }}
                              //onChange={onChange}
                            />
                          </Form.Item> */}
                          <Form.Item name="details">
                            <Input.TextArea
                              placeholder="Inquiry"
                              showCount
                              maxLength={100}
                              style={{
                                // paddingTop: "1em",
                                // height: "3.7em",
                                borderRadius: "3px",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.11) 2px 8px 13px 5px",
                                border: "none",
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 m-t-lg-12 text-center">
                          <Form.Item>
                            <Button
                              style={{
                                fontFamily: "Arial",
                                border: "1px solid #E40401",
                                fontSize: "15px",
                                fontWeight: 600,
                                borderRadius: "3px",
                                backgroundColor: "#E40401",
                                color: "white",
                                height: "55px",
                                paddingLeft: "3em",
                                paddingRight: "3em",
                                textShadow: "none",
                                margin: "auto",
                                marginTop: "1em",
                              }}
                              type="primary"
                              htmlType="submit"
                            >
                              CONTACT ME
                            </Button>{" "}
                            <p
                              style={{
                                color: "black",
                                marginTop: "1em",
                                fontSize: "13px",
                                lineHeight: "1.5em",
                                // float: "left",
                              }}
                            >
                              By submitting this form, you agree to our{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  textDecoration: "underline",
                                }}
                              >
                                <Link style={{ color: "black" }} to="/terms">
                                  Terms of Use
                                </Link>
                              </span>{" "}
                              and{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  textDecoration: "underline",
                                }}
                              >
                                <Link
                                  style={{ color: "black" }}
                                  to="/privacy-policy"
                                >
                                  Privacy Statement
                                </Link>
                              </span>
                              .
                            </p>{" "}
                          </Form.Item>{" "}
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </section>{" "}
      </div>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;

    return (
      <div id="wrap" className="color1-inher">
        {this.renderSearchBar()}
        <div id="wrap-body" className="p-t-lg-45">
          <div className="container-custom">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default withRouter(SharedLayout);
