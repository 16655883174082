import React from "react";
import { connect } from "react-redux";
import { Link, withRouter, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Tabs,
  Badge,
  Image,
  Radio,
  Space,
  Checkbox,
  Form,
  Select,
  Input,
  Modal,
  Collapse,
  Button,
  Spin,
  DatePicker,
  InputNumber,
  Upload,
  message,
  Steps,
  notification,
} from "antd";
import {
  WhatsAppOutlined,
  MailOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import config from "./server.config";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../../redux/actions";
import moment from "moment";

const dateValue = moment("2024-07-31T05:56:09.877Z");

const { Step } = Steps;
const { Dragger } = Upload;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const props = {
  name: "file",
  multiple: true,
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const { Option, OptGroup } = Select;
const { TextArea } = Input;
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Group, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledDatePicker = styled(DatePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledCheckbox = styled(Checkbox)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #777 !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: white !important;
    border: 0px solid white !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #F91700 !important;
}
.ant-checkbox-checked::after {
  background-color: transparent !important;
  border-color: red !important;
  border: 0px solid red !important;
}
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid lightgrey !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;

const dateFormat = "YYYY/MM/DD";
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class StockDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      stock: [],
      valueRadioSex: "",
      valueRadioEthnicGroup: "",
      valueRadioLanguagePreference: "",
      valueRadioAccountType: "",
      firstName: "",
      surname: "",
      cellPhone: "",
      loading: false,
      selectedDate: moment("2024-07-31T05:56:09.877Z"),
      current: 0,
      isQualified: false,
      isStep2Valid: false,
      isStep3Valid: false,
      isStep4Valid: false,
      // price: this.props.selectedStock.price,
      // stockNr: this.props.selectedStock.StockNo,
      // vin: this.props.selectedStock.VIN,
      // year: this.props.selectedStock.Year,
      // model: this.props.selectedStock.model,
      leadReference: "",
      errorMessage: "",
      submitDisabled: false,
      loading: true, // Initially set loading to true
      test: false,
      mainIndex: 0,
      mainAnimation: false,
      thumbIndex: 0,
      thumbAnimation: false,
      items: [], // Initialize items as an empty array
      fileList: {
        idDocument: [],
        drivingLicence: [],
        bankStatements: [],
        payslips: [],
        proofOfAddress: [],
      },

    };
    this.onFinishApply = this.onFinishApply.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }
  formRef = React.createRef();
  handleFileChange = (key, info) => {
    const newFileList = info.fileList.slice(-this.getLimit(key)); // Limit the number of files
    this.setState({
      fileList: {
        ...this.state.fileList,
        [key]: newFileList,
      },
    });
  };

  getLimit = (key) => {
    switch (key) {
      case 'bankStatements':
      case 'payslips':
        return 3; // Limit to 3 files for bank statements and payslips
      default:
        return 1; // Limit to 1 file for all other documents
    }
  };

  beforeUpload = (key, file, fileList) => {
    const maxSize = 2; // Maximum file size in MB
    if (file.size / 1024 / 1024 > maxSize) {
      message.error(`File must be smaller than ${maxSize}MB!`);
      return Upload.LIST_IGNORE; // Prevent file from being uploaded
    }

    if (fileList.length > this.getLimit(key)) {
      message.error(`You can only upload up to ${this.getLimit(key)} files for ${key}`);
      return Upload.LIST_IGNORE; // Prevent further uploads
    }

    return false; // Prevent auto upload
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
  onFormChange = () => {
    const form = this.formRef.current;
    const values = form.getFieldsValue();
    const isQualified = this.checkQualification(values);
    const isStep2Valid = this.checkStep2Validation(values);
    const isStep3Valid = this.checkStep3Validation(values);
    const isStep4Valid = this.checkStep4Validation(values);
    this.setState({ isQualified, isStep2Valid, isStep3Valid, isStep4Valid });
  };

  checkQualification(values) {
    return (
      values.applyingEntity &&
      values.applyingIncome === "Yes" &&
      values.applyingCitizen === "Yes" &&
      values.applyingDebt === "No" &&
      values.applyingConsent === "Yes"
    );
  }

  checkStep2Validation(values) {
    const requiredFields = [
      "applyLastName",
      "applyFirstName",
      "idNumber",
      "relationshipStatus",
      "applyEmail",
      "cellNumber",
      "streetAddressLine",
      "postalZip",
      "nextKin",
      "kinRelationshipStatus",
      "occupation",
      "addressPeriod",
    ];
    return requiredFields.every((field) => values[field]);
  }

  checkStep3Validation(values) {
    const requiredFields = [
      "bankingDetails",
      "bank",
      "branchCode",
      "accountNumber",
      "accountType",
    ];
    return requiredFields.every((field) => values[field] !== undefined);
  }
  checkStep4Validation(values) {
    const requiredFields = [
      "basicMonthly",
      "totalSalary",
      "monthlyCommission",
      "netPay",
      "otherIncome",
      "bondRepayment",
      "creditCard",
      "clothingAccount",
      "insurancePayments",
      "ratesWaterElectricity",
      "personalLoanPayments",
      "furnitureAccounts",
      "overdraftPayments",
      "telephonePayment",
      "foodEntertainment",
      "transport",
    ];
    return requiredFields.every((field) => values[field] !== undefined);
  }

  handleNextClick = () => {
    const { current, isQualified, isStep2Valid, isStep3Valid, isStep4Valid } =
      this.state;
    if (current === 1 && !isQualified) {
      notification.error({
        message: 'Qualification Error',
        description: 'You do not meet the qualification criteria to proceed.',
      });
    } else if (current === 2 && !isStep2Valid) {
      notification.error({
        message: 'Validation Error',
        description: 'Please complete all required fields marked with an asterisk (*)',
      });
    } else if (current === 3 && !isStep3Valid) {
      notification.error({
        message: 'Validation Error',
        description: 'Please complete all required fields marked with an asterisk (*)',
      });
    }
    else if (current === 4 && !isStep4Valid) {
      notification.error({
        message: 'Validation Error',
        description: 'Please complete all required fields marked with an asterisk (*)',
      });
    } else {
      this.next();
    }
  };

  slideNext = () => {
    const { thumbAnimation, thumbIndex } = this.state;
    if (!thumbAnimation && thumbIndex < this.state.items.length - 1) {
      this.setState({
        thumbAnimation: true,
        thumbIndex: thumbIndex + 1,
      });
    }
  };

  slidePrev = () => {
    const { thumbAnimation, thumbIndex } = this.state;
    if (!thumbAnimation && thumbIndex > 0) {
      this.setState({
        thumbAnimation: true,
        thumbIndex: thumbIndex - 1,
      });
    }
  };

  syncMainBeforeChange = () => {
    this.setState({
      mainAnimation: true,
    });
  };

  syncMainAfterChange = (e) => {
    this.setState({
      mainAnimation: false,
    });

    if (e.type === "action") {
      this.setState({
        thumbIndex: e.item,
        thumbAnimation: false,
      });
    } else {
      this.setState({
        mainIndex: this.state.thumbIndex,
      });
    }
  };

  syncThumbs = (e) => {
    this.setState({
      thumbIndex: e.item,
      thumbAnimation: false,
    });

    if (!this.state.mainAnimation) {
      this.setState({
        mainIndex: e.item,
      });
    }
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  async componentDidMount() {
    try {
      console.log(this.props.selectedStock);
      document.documentElement.scrollTop = 0;

      // Set loading to true
      this.setState({ loading: true });

      const res = await fetch(
        "https://www.devvcsappcloud.com/mtgroup/api/requisition/extractxmldataclient",
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );

      if (res.ok) {
        const result = await res.json();
        const allStockData = result.map((item) => item.root.row).flat();

        if (allStockData.length > 0) {
          // Apply data processing logic here to filter and set the data to state
          // Example logic provided in the previous response
          const filteredStockData = allStockData.filter((row) => row);

          this.setState({ stock: filteredStockData });
        } else {
          throw new Error("Unexpected response structure or empty data.");
        }
      } else {
        throw new Error("Failed to fetch stock data.");
      }

      // Set loading to false
      this.setState({ loading: false });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle errors as needed, e.g., show an error message to the user
      this.setState({ loading: false, error: error.message });
    }
  }

  onChangeRadioSex = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadioSex: e.target.value,
    });
  };

  onChangeRadioEthnicGroup = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadioEthnicGroup: e.target.value,
    });
  };
  onChangeRadioLanguagePreference = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadioLanguagePreference: e.target.value,
    });
  };
  onChangeRadioAccountType = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadioAccountType: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModalChat = () => {
    this.setState({
      visibleCallback: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("https://www.megatheron.co.za/stock");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleOkFinance = (e) => {
    console.log(e);
    this.setState({
      visibleCallback: false,
    });
    // window.location.assign("https://www.megatheron.co.za/stock");
  };

  handleCancelFinance = (e) => {
    console.log(e);
    this.setState({
      visibleCallback: false,
    });
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };

  handleSubmitApply = (values) => {
    this.onFinishApply(values);
  };

  onFinishApply = () => {
    this.setState({ loading: true });
    const form = this.formRef.current;
    const values = form.getFieldsValue(true);

    const docDefinition = {
      content: [
        { text: "Vehicle Financing Application", style: "header" },
        { text: "PERSONAL DETAILS", style: "subheader" },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              [
                { text: "Field", style: "tableHeader" },
                { text: "Value", style: "tableHeader" },
              ],
              ["First Name", values.applyFirstName || ""],
              ["Last Name", values.applyLastName || ""],
              ["ID Number", values.idNumber || ""],
              ["Relationship Status", values.relationshipStatus || ""],
              ["Sex", values.sex || ""],
              // ["Spouse Name", values.spouseName || ""],
              // ["Wedding Date", values.weddingDate || ""],
              ["Email", values.applyEmail || ""],
              ["Cell Number", values.cellNumber || ""],
              ["Work Number", values.workNumber || ""],
              ["Alternative Number", values.alternativeNumber || ""],
              ["Street Address", values.streetAddressLine || ""],
              ["City", values.city || ""],
              ["State/Province", values.stateProvince || ""],
              ["Postal/Zip Code", values.postalZip || ""],
              ["Address Period", values.addressPeriod || ""],
              ["Next of Kin", values.nextKin || ""],
              [
                "Relationship to Next of Kin",
                values.kinRelationshipStatus || "",
              ],
              ["Next of Kin Address", values.nextKinAddress || ""],
              ["Next of Kin Cell Number", values.nextKinCellNumber || ""],
              ["Ethnic Group", values.ethnicGroup || ""],
              ["Language Preference", values.languagePreference || ""],
              ["Employer Details", values.employerDetails || ""],
              ["Employer Address", values.employerAddress || ""],
              ["Salary Date", values.salaryDate || ""],
              ["Telephone", values.tel || ""],
              ["Occupation", values.occupation || ""],
              ["Years at Company", values.yearsAtCompany || ""],
            ],
          },
          layout: "lightHorizontalLines", // optional
        },
        { text: "BANKING DETAILS", style: "subheader", margin: [0, 20, 0, 10] },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              [
                { text: "Field", style: "tableHeader" },
                { text: "Value", style: "tableHeader" },
              ],
              ["Banking Details", values.bankingDetails || ""],
              ["Bank", values.bank || ""],
              ["Branch Code", values.branchCode || ""],
              ["Account Number", values.accountNumber || ""],
              ["Account Type", values.accountType || ""],
            ],
          },
          layout: "lightHorizontalLines", // optional
        },
        { text: "BUDGET", style: "subheader", margin: [0, 20, 0, 10] },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              [
                { text: "Field", style: "tableHeader" },
                { text: "Value", style: "tableHeader" },
              ],
              ["Basic Monthly Income", values.basicMonthly ? "R" + values.basicMonthly : ""],
              ["Total Salary", values.totalSalary ? "R" + values.totalSalary : ""],
              ["Monthly Commission", values.monthlyCommission ? "R" + values.monthlyCommission : ""],
              ["Net Pay", values.netPay ? "R" + values.netPay : ""],
              ["Other Income", values.otherIncome ? "R" + values.otherIncome : ""],
              ["Source of Other Income", values.sourceOtherIncome ? "R" + values.sourceOtherIncome : ""],
              ["Bond Repayment", values.bondRepayment ? "R" + values.bondRepayment : ""],
              ["Credit Card", values.creditCard ? "R" + values.creditCard : ""],
              ["Clothing Account", values.clothingAccount ? "R" + values.clothingAccount : ""],
              ["Insurance Payments", values.insurancePayments ? "R" + values.insurancePayments : ""],
              ["Rates/Water/Electricity", values.ratesWaterElectricity ? "R" + values.ratesWaterElectricity : ""],
              ["Personal Loan Payments", values.personalLoanPayments ? "R" + values.personalLoanPayments : ""],
              ["Furniture Accounts", values.furnitureAccounts ? "R" + values.furnitureAccounts : ""],
              ["Overdraft Payments", values.overdraftPayments ? "R" + values.overdraftPayments : ""],
              ["Telephone Payment", values.telephonePayment ? "R" + values.telephonePayment : ""],
              ["Food/Entertainment", values.foodEntertainment ? "R" + values.foodEntertainment : ""],
              ["Transport", values.transport ? "R" + values.transport : ""],
            ],
          },
          layout: "lightHorizontalLines", // optional
        },
        { text: "VEHICLE", style: "subheader", margin: [0, 20, 0, 10] },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [
              [
                { text: "Field", style: "tableHeader" },
                { text: "Value", style: "tableHeader" },
              ],
              ["Body Type", values.BodyType || ""],
              ["New Used", values.NewUsed || ""],
              ["Brand", values.Brand || ""],
              ["Model", values.Model || ""],
              ["Stock No", values.StockNo || ""],
              ["Dealership Name", values.DealershipName || ""],
              ["Dealership ID", values.DealershipID || ""],
              ["VIN", values.VIN || ""],

            ],
          },
          layout: "lightHorizontalLines", // optional
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 15,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        font: "Roboto",
      },
    };

    // Convert PDF to base64 and then handle the form data
    pdfMake.createPdf(docDefinition).getBase64((pdfBase64) => {
      // Array to hold all attachments
      const attachments = [
        {
          content: pdfBase64,
          filename: "Vehicle_Financing_Application.pdf",
          type: "application/pdf",
          disposition: "attachment",
        },
      ];

      // Convert each uploaded file to base64 and add to attachments
      const fileConversionPromises = Object.keys(this.state.fileList).flatMap(key => {
        return this.state.fileList[key].map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => {
              const base64Data = reader.result.split(',')[1]; // Extract base64 part
              attachments.push({
                content: base64Data,
                filename: file.name,
                type: file.type,
                disposition: "attachment",
              });
              resolve();
            };
            reader.onerror = reject;
          });
        });
      });

      // Wait for all files to be converted before sending the email
      Promise.all(fileConversionPromises)
        .then(() => {
          // Prepare the message payload
          const MSG = {
            to: "leads@megatheron.co.za",
            from: "info@mtgroup.co.za",
            subject: "New Vehicle Financing request",
            text: " ",
            html: `
              <p>You have received a new vehicle financing request from Mega Theron Group website.</p>
              <p>Please see the attached PDF and uploaded documents for details.</p>
            `,
            attachments,
          };

          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          })
            .then(async (res) => {
              if (!res.ok) {
                console.log("handle error");
                message.error('Failed to send email.');
              } else {
                console.log("handle success");
                message.success('Email sent successfully!');
              }
            })
            .finally(() => {
              this.setState({
                loading: false, visible: true,
                visibleGetQuote: false,
                submitDisabled: true,
              }); // Set loading to false when email is sent
              window.location.assign("/stock");
            });
        })
        .catch(error => {
          console.error('Error converting files:', error);
          this.setState({ loading: false });
          message.error('Failed to convert files.');
        });
    });
  };
  onFinish = async (values) => {
    // event.preventDefault();

    const payload = {
      lead: {
        // dealerRef: config.dealerRef,
        // dealerFloor: config.dealerFloor,
        // source: config.source,
        dealerRef: values.brand[0],
        dealerFloor:
          (values.brand[0] === "MT001" && values.NewUsed[0] === "USED") ||
            values.NewUsed[0] === "DEMO"
            ? "USED"
            : values.brand[0] === "MT002" && values.NewUsed[0] === "NEW"
              ? "NEWJAC"
              : values.brand[0] === "KMK18" && values.NewUsed[0] === "NEW"
                ? "NEWKIA"
                : values.brand[0] === "MT003" && values.NewUsed[0] === "NEW"
                  ? "NEWHAVAL"
                  : (values.brand[0] === "MT003" && values.NewUsed[0] === "USED") ||
                    values.NewUsed[0] === "DEMO"
                    ? "USED"
                    : values.brand[0] === "MT003" && values.NewUsed[0] === "NEW"
                      ? "NEWHAVAL"
                      : (values.brand[0] === "MT003" && values.NewUsed[0] === "USED") ||
                        values.NewUsed[0] === "DEMO"
                        ? "USED"
                        : values.brand[0] === "MT005" && values.NewUsed[0] === "NEW"
                          ? "NEWMAHINDRA"
                          : (values.brand[0] === "MT005" && values.NewUsed[0] === "USED") ||
                            values.NewUsed[0] === "DEMO"
                            ? "USED"
                            : values.brand[0] === "MT004" && values.NewUsed[0] === "NEW"
                              ? "NEWHAVAL"
                              : (values.brand[0] === "MT004" && values.NewUsed[0] === "USED") ||
                                values.NewUsed[0] === "DEMO"
                                ? "USED"
                                : values.brand[0] === "MT004" && values.NewUsed[0] === "NEW"
                                  ? "NEWHAVAL"
                                  : (values.brand[0] === "MT004" && values.NewUsed[0] === "USED") ||
                                    values.NewUsed[0] === "DEMO"
                                    ? "USED"
                                    : values.brand[0] === "MT006" && values.NewUsed[0] === "NEW"
                                      ? "NEWHAVAL"
                                      : (values.brand[0] === "MT006" && values.NewUsed[0] === "USED") ||
                                        values.NewUsed[0] === "DEMO"
                                        ? "USED"
                                        : values.brand[0] === "MT006" && values.NewUsed[0] === "NEW"
                                          ? "NEWHAVAL"
                                          : (values.brand[0] === "MT006" && values.NewUsed[0] === "USED") ||
                                            values.NewUsed[0] === "DEMO"
                                            ? "USED"
                                            : values.brand[0] === "MT007" && values.NewUsed[0] === "NEW"
                                              ? "NEWHAVAL"
                                              : (values.brand[0] === "MT007" && values.NewUsed[0] === "USED") ||
                                                values.NewUsed[0] === "DEMO"
                                                ? "USED"
                                                : values.brand[0] === "MT007" && values.NewUsed[0] === "NEW"
                                                  ? "NEWHAVAL"
                                                  : (values.brand[0] === "MT007" && values.NewUsed[0] === "USED") ||
                                                    values.NewUsed[0] === "DEMO"
                                                    ? "USED"
                                                    : values.brand[0] === "MT008" && values.NewUsed[0] === "NEW"
                                                      ? "NEWCHERY"
                                                      : (values.brand[0] === "MT008" && values.NewUsed[0] === "USED") ||
                                                        values.NewUsed[0] === "DEMO"
                                                        ? "USED"
                                                        : null,

        source: "MT-DEALERWEBSITE",
        contact: {
          firstName: this.state.firstName,
          surname: this.state.surname,
          cellPhone: this.state.cellPhone,
        },
        seeks: {
          used:
            values.NewUsed[0] === "USED" || values.NewUsed[0] === "DEMO"
              ? "1"
              : "0",
          brand: values.brand[0],
          price: values.price[0],
          model: values.Model[0],
          // testDrive: this.state.valueRadio,
          stockNr: values.StockNo[0],
          vin: values.VIN[0],
          year: values.Year[0],
        },
        // referrer: {
        //   firstName: '',
        //   surname: '',
        //   email: '',
        //   cellPhone: ''
        // },
        // options: {
        //   stockNrIdent: ''
        // }
      },
    };

    try {
      this.setState({ loading: true }); // Set loading to true before making the API request
      const response = await axios.post(
        "https://www.vcsappcloud.com/mtgroup/api/requisition/addlead",
        { lead: payload } // Wrap the payload in a 'lead' object
        // {
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: 'token'
        //   }
        // }
      );

      if (response.data.code === "00") {
        this.setState({
          leadReference: response.data.leadReference,
          errorMessage: "",
          // visible: true,
          submitDisabled: true,
        });
        window.location.href = `https://www.megatheron.co.za/thank-you?leadReference=${response.data.leadReference}`;
      } else {
        this.setState({ errorMessage: response.data.message });
      }
    } catch (error) {
      this.setState({
        errorMessage: "An error occurred while processing the request.",
      });
    } finally {
      this.setState({ loading: false }); // Set loading back to false after the API request is complete
    }
  };

  render() {
    console.log(this.props.selectedStock);
    const { current, isQualified, isStep2Valid } = this.state;
    const { location, loading } = this.props;
    const url = location.pathname;
    const selectedId = url.split("/").pop();

    var {
      Brand,
      ModelRange,
      Model,
      Year,
      email,
      number,
      regDate,
      Colour,
      intColour,
      retailPriceIncl,
      FuelType,
      capacityCC,
      BodyType,
      Transmission,
      engineSize,
      vehicleDrive,
      unladenWeight,
      axles,
      doors,
      cylinders,
      passengers,
      immobiliser,
      dateBuilt,
      extrasAndAccessories,
      comments,
      price,
      DealershipName,
      brand,
      email,
      onSale,
      NewUsed,
      Mileage,
      fuelTankSize,
      degreeCamera,
      fourWheelDrive,
      abs,
      driverAssistance,
      airBags,
      aircon,
      alarm,
      mobileCarPlay,
      automaticLights,
      automaticWipers,
      autonomousBraking,
      blindSpotMonitoring,
      bluetooth,
      brakeAssist,
      climateControl,
      cruiseControl,
      daytimeLights,
      electricParkingBrake,
      elctricWindows,
      electronicStability,
      collisionWarning,
      headDisplay,
      heatedSeats,
      hillAssist,
      hillControl,
      keylessEntry,
      laneWarning,
      laneAssist,
      leatherSeats,
      ledLights,
      navigation,
      paddleShift,
      parkControl,
      powerBoot,
      powerSeat,
      remoteStart,
      reverseCamera,
      sunroof,
      touchScreen,
      towbar,
      signRecognition,
      tyreMonitor,
      usbPort,
      voiceRecognition,
      warranty,
      wirelessCharging,
      xenonLights,
      frontLeftImage,
      frontImage,
      frontRightImage,
      rightSideImage,
      wheelsImage,
      backRightImage,
      backImage,
      backLeftImage,
      leftSideImage,
      bootImage,
      frontSeatImage,
      backSeatImage,
      dashPassengerImage,
      dashDriverImage,
      dashDriverSideImage,
      radioImage,
      radioCloseImage,
      gearsImage,
      steeringImage,
      spareKeyImage,
      branchImage,
      VIN,
    } = this.props.selectedStock;

    const generateBodyType = (stock, selectedId) => {
      if (stock && stock.ID && stock.ID.includes(selectedId)) {
        const subject = encodeURIComponent(`${stock.BodyType}`);
      }
      return null;
    };
    const generateEmailLink = (stock, selectedId) => {
      // Check if stock and ID are defined and if the ID includes selectedId
      if (stock && stock.ID && stock.ID.includes(selectedId)) {
        const subject = encodeURIComponent(
          `Check out this vehicle: ${stock.Brand} ${stock.Model}`
        );
        const body = encodeURIComponent(
          `Make: ${stock.Brand}\n` +
          `Model: ${stock.Model}\n` +
          `Price: R${stock.Price}\n` +
          `Branch: ${stock.DealershipName}\n` +
          `Year: ${stock.Year}\n` +
          `Condition: ${stock.NewUsed}\n` +
          `View Now: https://www.megatheron.co.za${url}`
        );

        // Construct the mailto link
        return `mailto:?subject=${subject}&body=${body}`;
      }

      // Return a default value or handle the case when stock is not defined
      return null; // You can return an empty string, null, or any other default value
    };
    const generateEmailLinkReserve = (stock, selectedId) => {
      // Check if stock and ID are defined and if the ID includes selectedId
      if (stock && stock.ID && stock.ID.includes(selectedId)) {
        const subject = encodeURIComponent(
          `I'm interested in learning more about this vehicle: ${stock.Brand} ${stock.Model}`
        );
        const body = encodeURIComponent(
          `Make: ${stock.Brand}\n` +
          `Model: ${stock.Model}\n` +
          `Price: R${stock.Price}\n` +
          `Branch: ${stock.DealershipName}\n` +
          `Year: ${stock.Year}\n` +
          `Condition: ${stock.NewUsed}\n` +
          `Stock No: ${stock.StockNo}\n` +
          `Vehicle Link: https://www.megatheron.co.za${url}`
        );

        // Construct the mailto link
        return `mailto:leads@megatheron.co.za?subject=${subject}&body=${body}`;
      }

      // Return a default value or handle the case when stock is not defined
      return null; // You can return an empty string, null, or any other default value
    };

    const generateWhatsAppLink = (stock, selectedId) => {
      // Check if stock and ID are defined and if the ID includes selectedId
      if (stock && stock.ID && stock.ID.includes(selectedId)) {
        const message =
          `Check out this vehicle:\n\n` +
          `Make: ${stock.Brand}\n` +
          `Model: ${stock.Model}\n` +
          `Price: R${stock.Price}\n` +
          `Branch: ${stock.DealershipName}\n` +
          `Year: ${stock.Year}\n` +
          `Condition: ${stock.NewUsed}\n` +
          `View Now: \n${"https://www.megatheron.co.za" + url}\n`;

        // Encode the message for the URL
        const encodedMessage = encodeURIComponent(message);

        // Construct the WhatsApp share link
        return `https://wa.me/?text=${encodedMessage}`;
      }

      // Return a default value or handle the case when stock is not defined
      return null; // You can return an empty string, null, or any other default value
    };
    // const randomizedStock = this.state.stock.sort(() => Math.random() - 0.5);
    // const randomizedStock = this.state.stock.sort(() => Math.random() - 0.5);

    return (
      // <Spin spinning={this.state.loading} tip="Loading...">
      <div>
        {this.state.stock.map((stock, index) => {
          if (stock.ID.includes(selectedId)) {
            const thevalueinFormbody = stock.BodyType;
            return (
              <div className="col-lg-12" key={index}>
                <div className="wrap-body-inner">
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="ht-breadcrumb pull-left">
                          <li className="home-act">
                            <a href="https://www.megatheron.co.za/stock">
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="active">BACK TO SEARCH</li>
                          {/* <li className="active">{stock.Brand}</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <section className="m-t-lg-30 m-t-xs-0">
                    <div className="product_detail no-bg p-lg-0">
                      <div className="row">
                        <div className="col-md-12 col-lg-6">
                          <h4
                            style={{
                              marginTop: window.isPhone ? "1em" : "",
                              paddingLeft: "0.5em",
                              marginBottom: -20,
                            }}
                            className="product-name"
                          //style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <span className="f-22">
                              {stock.Brand} <br />
                              <p className="f-16">{stock.Model}</p>
                            </span>
                          </h4>{" "}
                          <div className="product-img-lg">
                            <AliceCarousel
                              activeIndex={this.state.mainIndex}
                              animationType="fadeout"
                              animationDuration={800}
                              disableDotsControls
                              disableButtonsControls
                              items={stock.Pic.map((pic, index) =>
                                pic._ ? (
                                  <Image
                                    style={{
                                      width: "100%",
                                      height: window.isPhone ? "25em" : "32em",
                                      objectFit: "cover",
                                    }}
                                    key={index} // Make sure to add a unique key for each image
                                    // className="product-details-img"
                                    src={pic._}
                                    alt=""
                                  />
                                ) : null
                              )}
                              mouseTracking={!this.state.thumbAnimation}
                              onSlideChange={this.syncMainBeforeChange}
                              onSlideChanged={this.syncMainAfterChange}
                              touchTracking={!this.state.thumbAnimation}
                            />
                            <br />
                            <Image.PreviewGroup>
                              <Slider {...settings}>
                                {stock.Pic.map((pic, index) =>
                                  pic._ ? (
                                    <Image
                                      key={index} // Make sure to add a unique key for each image
                                      className="product-details-img"
                                      src={pic._}
                                      alt=""
                                    />
                                  ) : null
                                )}
                              </Slider>
                            </Image.PreviewGroup>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="col-md-12 col-lg-6">
                          {/* <div className="f-12 mn-cl">
                            Price
                          </div> */}
                          <div
                            className="f-40 m-b-lg-5 m-t-lg-0 mn-cl"
                            style={{
                              textAlign: window.isPhone ? "center" : "",
                            }}
                          >
                            <CurrencyFormat
                              value={parseFloat(stock.Price)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"R"}
                              //format='R ### ### ### ###'
                              renderText={(value) => (
                                <div
                                  style={{
                                    lineHeight: 1,
                                    fontWeight: 500,
                                    float: window.isPhone ? "" : "right",
                                    marginTop: "-0.5em",
                                  }}
                                >
                                  {value}
                                </div>
                              )}
                            />
                            <p
                              className="f-16"
                              style={{
                                float: window.isPhone ? "" : "left",
                                marginBottom: "0em",
                              }}
                            >
                              Finance From{" "}
                              <span style={{ fontWeight: 600 }}>
                                R {stock.MonthlyRepayment} p/m
                              </span>
                            </p>
                          </div>
                          <a
                            // href={
                            //   window.isPhone
                            //     ? "tel:+27825772156"
                            //     : generateEmailLinkReserve(stock, selectedId)
                            // }
                            style={{ fontSize: "20px" }}
                          >
                            <Button
                              onClick={this.showModalChat}
                              style={{
                                display: window.isPhone ? "none" : "",
                                border: "1px solid rgb(32, 26, 32)",
                                fontSize: "15px",
                                fontWeight: 600,
                                borderRadius: "0.3em",
                                backgroundColor: "rgb(32, 26, 32)",
                                color: "white",
                                height: "40px",
                                width: "100%",
                                paddingLeft: "5em",
                                paddingRight: "5em",
                                textShadow: "none",
                                margin: "auto",
                                marginTop: "1em",
                              }}
                              htmlType="submit"
                            >
                              Apply For Finance
                            </Button>
                          </a>

                          <div className="m-t-lg-0">
                            <div
                              style={{
                                textAlign: window.isPhone ? "center" : "",
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingBottom: 20,
                              }}
                              className="banner-item banner-bg-10 color-inher m-t-lg-30"
                            >
                              <h3
                                className="f-weight-300"
                                style={{ marginBottom: "0em" }}
                              >
                                <strong style={{ lineHeight: "1em" }}>
                                  Enquire Now
                                </strong>
                              </h3>
                              <p style={{ margin: "auto" }}>
                                Speak to a Product Specialist today!
                              </p>
                            </div>
                            <div className="bg1-gray-2">
                              <div id="book-service">
                                <Form
                                  initialValues={{
                                    remember: true,
                                  }}
                                  name="wrap"
                                  labelCol={{ flex: "110px" }}
                                  labelAlign="left"
                                  labelWrap
                                  wrapperCol={{ flex: 1 }}
                                  colon={false}
                                  onFinish={this.handleSubmit}
                                  style={{ maxWidth: "80vw", margin: "auto" }}
                                >
                                  <div>
                                    <div className="row">
                                      <div className="col-sm-12 col-md-12 col-lg-12">
                                        <Form.Item
                                          name="firstName"
                                          rules={[
                                            { required: true, message: false },
                                          ]}
                                          hasFeedback
                                        >
                                          <StyledInput
                                            minLength={3}
                                            placeholder="First Name"
                                            type="text"
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.handleInputChange}
                                          />
                                        </Form.Item>
                                      </div>

                                      <div className="col-sm-12 col-md-12 col-lg-12">
                                        <Form.Item
                                          type="text"
                                          name="surname"
                                          rules={[
                                            { required: true, message: false },
                                          ]}
                                          hasFeedback
                                        >
                                          <StyledInput
                                            minLength={3}
                                            placeholder="Surname"
                                            type="text"
                                            name="surname"
                                            value={this.state.surname}
                                            onChange={this.handleInputChange}
                                          />
                                        </Form.Item>
                                      </div>
                                      <div className="col-sm-12 col-md-12 col-lg-12">
                                        <Form.Item
                                          name="cellPhone"
                                          rules={[
                                            { required: true, message: false },
                                            {
                                              min: 10,
                                              message:
                                                "Number must have a minimum of 10 digits",
                                            },
                                            {
                                              max: 10,
                                              message:
                                                "Number must have a maximum of 10 digits",
                                            },
                                          ]}
                                          hasFeedback
                                        >
                                          <StyledInput
                                            style={{ marginBottom: 0 }}
                                            placeholder="Number"
                                            type="number"
                                            name="cellPhone"
                                            value={this.state.cellPhone}
                                            onChange={this.handleInputChange}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-sm-4 col-md-4 col-lg-4"
                                    style={{ display: "none" }}
                                  >
                                    <Form.Item
                                      type="text"
                                      name="body"
                                      initialValue={stock.BodyType}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="make"
                                      initialValue={stock.Brand}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="Model"
                                      initialValue={stock.Model}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="StockNo"
                                      initialValue={stock.StockNo}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="price"
                                      initialValue={parseFloat(stock.Price)}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="branch"
                                      initialValue={stock.DealershipName}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="brand"
                                      initialValue={stock.DealershipID}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="VIN"
                                      initialValue={stock.VIN}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="Year"
                                      initialValue={stock.Year}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                    <Form.Item
                                      type="text"
                                      name="NewUsed"
                                      initialValue={stock.NewUsed}
                                      rules={[
                                        { required: false, message: false },
                                      ]}
                                      hasFeedback
                                    >
                                      <StyledInput />
                                    </Form.Item>
                                  </div>
                                  <br />
                                  <Button
                                    disabled={
                                      this.state.submitDisabled === true
                                        ? true
                                        : false
                                    }
                                    style={{
                                      border: "1px solid #F91700",
                                      fontSize: "15px",
                                      fontWeight: 600,
                                      borderRadius: "0.3em",
                                      backgroundColor: "#F91700",
                                      color: "white",
                                      height: "45px",
                                      width: "100%",
                                      paddingLeft: "3em",
                                      paddingRight: "3em",
                                      textShadow: "none",
                                      margin: "auto",
                                      marginTop: "-1em",
                                    }}
                                    htmlType="submit"
                                  >
                                    Submit
                                  </Button>
                                  <p
                                    style={{
                                      color: "black",
                                      //marginBottom: "2.5em",
                                      marginTop: "1em",
                                      fontSize: "13px",
                                      lineHeight: "1.5em",
                                    }}
                                  >
                                    By submitting this form, you agree to our{" "}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      <Link
                                        style={{ color: "black" }}
                                        to="/terms"
                                      >
                                        Terms of Use
                                      </Link>
                                    </span>{" "}
                                    and{" "}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      <Link
                                        style={{ color: "black" }}
                                        to="/privacy-policy"
                                      >
                                        Privacy Statement
                                      </Link>
                                    </span>
                                    .<br />
                                    {this.state.errorMessage && (
                                      <p>Error: {this.state.errorMessage}</p>
                                    )}
                                  </p>{" "}
                                </Form>
                              </div>
                            </div>

                            <div className="f-12">Share</div>
                            <br />
                            <a
                              href={generateEmailLink(stock, selectedId)}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "grey",
                                padding: "0.8em",
                                paddingTop: "1.3em",
                                marginTop: "1em",
                              }}
                            >
                              <MailOutlined
                                style={{ fontSize: "22px", color: "white" }}
                              />
                            </a>
                            <a
                              href={generateWhatsAppLink(stock, selectedId)}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                backgroundColor: "#25d366",
                                padding: "0.8em",
                                paddingTop: "1.3em",
                                marginLeft: "0.3em",
                                marginTop: "1em",
                              }}
                            >
                              <WhatsAppOutlined
                                style={{ fontSize: "22px", color: "white" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <Badge.Ribbon text={stock.NewUsed} color="red">
                      <div
                        className="f-15 m-t-lg-0 m-b-lg-0 m-t-lg-30"
                      // style={{ height: "em" }}
                      >
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="m-b-lg-30 bg-gray-fa bg1-gray-2 p-lg-30 p-xs-15">
                              {stock.BodyType ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    style={{ width: "1.5em" }}
                                    className="fa fa-stock"
                                    aria-hidden="true"
                                  ></i>
                                  {"   "}
                                  {stock.BodyType}
                                </span>
                              ) : null}
                              {window.isPhone ? <br /> : ""}
                              {stock.Year ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    style={{ width: "1.5em" }}
                                    className="fa fa-calendar-o"
                                    aria-hidden="true"
                                  ></i>
                                  {"   "}
                                  {stock.Year}
                                </span>
                              ) : null}{" "}
                              {window.isPhone ? <br /> : ""}
                              {stock.DealershipName ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    style={{ width: "1.5em" }}
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {"   "}
                                  {stock.DealershipName[0].replace(
                                    /Mega Theron Group \(Pty\) Ltd t\/a|Mega Theron Group \(Pty\) Ltd  t\/a|MTG Klerksdorp t\/a/g,
                                    ""
                                  )}
                                  <br />
                                </span>
                              ) : null}{" "}
                              {stock.FuelType ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    style={{ width: "1.5em" }}
                                    className="fa fa-cog"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {stock.FuelType}
                                </span>
                              ) : null}
                              {window.isPhone ? <br /> : ""}
                              {stock.Transmission ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    style={{ width: "1.5em" }}
                                    className="fa fa-ils"
                                    aria-hidden="true"
                                  ></i>
                                  {stock.Transmission}
                                </span>
                              ) : null}
                              {window.isPhone ? <br /> : ""}
                              {stock.Mileage ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    className="fa fa-road"
                                    style={{ width: "1.5em" }}
                                  ></i>
                                  <CurrencyFormat
                                    value={parseFloat(stock.Mileage)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    suffix={" km"}
                                    //format='R### ### ### ###'
                                    renderText={(value) => (
                                      <div style={{ display: "inline-block" }}>
                                        {value}
                                      </div>
                                    )}
                                  />
                                </span>
                              ) : null}
                              <br />
                              <br />
                              {stock.Features[0] ? (
                                <span style={{ marginRight: "2em" }}>
                                  <i
                                    style={{ width: "1.5em" }}
                                    className="fa fa-cog"
                                    aria-hidden="true"
                                  ></i>
                                  {stock.Features}
                                </span>
                              ) : null}
                              <br /> <br />
                              {stock.Comments ? (
                                <span style={{ marginRight: "2em" }}>
                                  {stock.Comments}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Badge.Ribbon>
                    <div className="product product-grid product-grid-2 stock m-t-lg-50 p-t-sm-35 m-b-lg-20 ">
                      <div className="heading">
                        <h3>You May Also Like</h3>
                      </div>
                      <div className="row">
                        <Slider {...settings}>
                          {this.state.stock
                            .filter(
                              (stock) =>
                                stock.BodyType[0].includes(
                                  thevalueinFormbody
                                ) && stock.ID[0] !== selectedId
                            )

                            .map((stock, index) => (
                              <div className="col-lg-12" key={index}>
                                <div className="product-item hover-img ">
                                  {stock.onSale === true ? (
                                    <span
                                      style={{
                                        background: "rgb(254, 95, 92)",
                                        borderRadius: "5em",
                                        padding: "0.7em",
                                        paddingTop: "1.1em",
                                        paddingBottom: "1em",
                                        position: "absolute",
                                        left: 10,
                                        top: 10,
                                        color: "white",
                                        fontSize: 11,
                                      }}
                                    >
                                      SALE
                                    </span>
                                  ) : null}
                                  {stock.NewUsed ? (
                                    <span
                                      style={{
                                        background:
                                          stock.NewUsed === "New"
                                            ? "#f919007d"
                                            : stock.NewUsed === "Demo"
                                              ? "#f919007d"
                                              : "#f919007d",
                                        borderRadius: "0em",
                                        padding: "0.3em",
                                        paddingRight: "0.8em",
                                        paddingLeft: "0.8em",
                                        position: "absolute",
                                        right: 10,
                                        top: 158,
                                        width: "4.3em",
                                        color: "white",
                                      }}
                                    >
                                      {stock.NewUsed}
                                    </span>
                                  ) : null}
                                  {stock.Year ? (
                                    <span
                                      style={{
                                        background: "#f919007d",
                                        borderRadius: "0em",
                                        padding: "0.3em",
                                        paddingRight: "0.8em",
                                        paddingLeft: "0.8em",
                                        position: "absolute",
                                        right: stock.NewUsed ? 75 : 10,
                                        top: 158,
                                        color: "white",
                                      }}
                                    >
                                      {stock.Year}
                                    </span>
                                  ) : null}
                                  <Link
                                    className="product-img"
                                    onClick={() => {
                                      this.props.fetchSingleStock(stock);
                                      window.scrollTo(0, 0); // Scroll to the top
                                    }}
                                    to={`/stocko/${stock.ID}`}
                                  >
                                    <img
                                      src={
                                        stock.Pic[0]
                                          ? stock.Pic[0]._
                                          : `${process.env.PUBLIC_URL}/images/coming-soon.png`
                                      }
                                      alt=""
                                    />
                                  </Link>
                                  <div className="product-caption">
                                    <h4
                                      className="product-name"
                                      style={{
                                        marginBottom: "0em",
                                        marginTop: "-1em",
                                      }}
                                    >
                                      <Link
                                        onClick={() => {
                                          this.props.fetchSingleStock(stock);
                                          window.scrollTo(0, 0); // Scroll to the top
                                        }}
                                        to={`/stocko/${stock.ID}`}
                                      >
                                        <span
                                          style={{
                                            display: "block",
                                            textTransform: "uppercase",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {stock.Brand}
                                          <p
                                            className="f-14"
                                            style={{
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              paddingRight: "3em",
                                              overflow: "hidden",
                                              width: "21em",
                                            }}
                                          >
                                            {/* 
                              <br />*/}
                                            {stock.Model}
                                          </p>
                                        </span>
                                      </Link>
                                    </h4>
                                    <div className="product-name">
                                      <span
                                        className="f-13 m-t-lg-5"
                                        style={{
                                          whiteSpace: "nowrap",
                                          float: "left",
                                          textAlign: "left",
                                          fontWeight: 400,
                                          color: "black",
                                        }}
                                      >
                                        {/* {stock.DealershipNameImage ? (
                              <img
                                src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.DealershipNameImage}`}
                                alt=""
                                style={{
                                  position: "absolute",
                                  marginTop: "-2.5em",
                                  objectFit: "cover",
                                  height: "2em",
                                }}
                              />
                            ) : ( */}
                                        <img
                                          src={
                                            stock.Brand.includes("MT003")
                                              ? `${process.env.PUBLIC_URL}/images/logo.png`
                                              : stock.Brand.includes("MT001")
                                                ? `${process.env.PUBLIC_URL}/images/mega-theron-traders.png`
                                                : stock.Brand.includes("KMK18")
                                                  ? `${process.env.PUBLIC_URL}/images/kia-image.png`
                                                  : stock.Brand.includes("MT005")
                                                    ? `${process.env.PUBLIC_URL}/images/mahindra-image.png`
                                                    : stock.Brand.includes("MT008")
                                                      ? `${process.env.PUBLIC_URL}/images/chery-image.png`
                                                      : stock.Brand.includes("GWM")
                                                        ? `${process.env.PUBLIC_URL}/images/gwm-image.png`
                                                        : stock.Brand.includes("HAVAL")
                                                          ? `${process.env.PUBLIC_URL}/images/haval-image.png`
                                                          : null // Return null for the default case
                                          }
                                          alt=""
                                          style={{
                                            position: "absolute",
                                            marginTop:
                                              stock.Brand.includes("MT003") ||
                                                stock.Brand.includes("MT001")
                                                ? "-2.5em"
                                                : stock.Brand.includes("MT008")
                                                  ? "-2.1em"
                                                  : stock.Brand.includes("HAVAL")
                                                    ? "-1.8em"
                                                    : "-1.9em",
                                            objectFit: "contain",
                                            width:
                                              stock.Brand.includes("MT003") ||
                                                stock.Brand.includes("MT001")
                                                ? "8.5em"
                                                : stock.Brand.includes("MT005")
                                                  ? "7em"
                                                  : stock.Brand.includes("MT008")
                                                    ? "3em"
                                                    : "5em",
                                          }}
                                        />
                                        {stock.DealershipID.includes("MT001")
                                          ? "Mega Theron Traders"
                                          : stock.DealershipID.includes("MT002")
                                            ? "JAC Klerksdorp"
                                            : stock.DealershipID.includes("MT003")
                                              ? "GWM/Haval Klerksdorp"
                                              : stock.DealershipID.includes("MT004")
                                                ? "GWM/Haval Potch"
                                                : stock.DealershipID.includes("MT005")
                                                  ? "Mahindra Mahikeng"
                                                  : stock.DealershipID.includes("MT006")
                                                    ? "GWM/Haval Mahikeng"
                                                    : stock.DealershipID.includes("MT007")
                                                      ? "Lichtenburg"
                                                      : stock.DealershipID.includes("MT008")
                                                        ? "Chery Klerksdorp"
                                                        : stock.DealershipID.includes("KMK18")
                                                          ? "Kia Mahikeng"
                                                          : "Unknown Dealership"}
                                      </span>
                                      <span
                                        className="f-23  m-t-lg-0 mn-cl"
                                        style={{ float: "right" }}
                                      >
                                        <CurrencyFormat
                                          value={parseFloat(stock.Price)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"R"}
                                          //format='R### ### ### ###'
                                          renderText={(value) => (
                                            <div>{value}</div>
                                          )}
                                        />
                                      </span>
                                    </div>
                                    <ul className="static-caption m-t-lg-20">
                                      <li>
                                        <i className="fa fa-clock-o"></i>
                                        {/*{stock.fuel}*/}
                                        {stock.FuelType}
                                      </li>
                                      <li>
                                        <i className="fa fa-tachometer"></i>
                                        {stock.Transmission}
                                      </li>
                                      <li>
                                        <i className="fa fa-road"></i>
                                        <CurrencyFormat
                                          value={parseFloat(stock.Mileage)} // Convert the mileage string to a number
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          suffix={"km"}
                                          renderText={(value) => (
                                            <div
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              {value}
                                            </div>
                                          )}
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  </section>{" "}
                  <Modal
                    visible={this.state.visible}
                    style={{ textAlign: "center" }}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.png`}
                      alt="Logo"
                      style={{ width: "65%", margin: "auto" }}
                    />
                    <h3
                      style={{
                        marginTop: "1em",
                        color: "black",
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                    >
                      Thank you for contacting
                      <br />
                      <span style={{ color: "rgb(214, 54, 41)" }}>
                        The Mega Theron Group.
                      </span>{" "}
                      <br />
                      <br />
                      <span style={{ color: "black ", fontSize: "17px" }}>
                        Our vehicle specialist will be in touch shortly.
                      </span>{" "}
                      <br /> <br />
                      <span
                        style={{ color: "rgb(214, 54, 41)", fontSize: "14px" }}
                      >
                        {this.state.leadReference && (
                          <p>
                            Your Reference Code: <br />
                            {this.state.leadReference}
                          </p>
                        )}
                      </span>{" "}
                      <br />
                    </h3>{" "}
                    <br />
                  </Modal>
                  <Modal
                    width={900}
                    visible={this.state.visibleCallback}
                    // visible={false}
                    style={{ top: 10 }}
                    onOk={this.handleOkFinance}
                    onCancel={this.handleCancelFinance}
                  >
                    <Form
                      style={{ margin: "auto" }}
                      id="finance"
                      onFinish={this.handleSubmitApply}
                      ref={this.formRef}
                      onValuesChange={this.onFormChange}
                      initialValues={{
                        remember: true,
                      }}
                      // initialValues={{
                      //   applyingEntity: undefined,
                      //   applyingIncome: undefined,
                      //   applyingCitizen: undefined,
                      //   applyingDebt: undefined,
                      //   applyingConsent: undefined,
                      // }}
                      name="wrap"
                      labelCol={{ flex: "110px" }}
                      labelAlign="left"
                      labelWrap
                      wrapperCol={{ flex: 1 }}
                      colon={false}
                    >
                      <section className="m-t-lg-10 m-t-xs-0 m-b-lg-20">
                        <div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div
                                className="bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15"
                                style={{
                                  borderRadius: "1.5em",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="m-b-lg-10">
                                  <div className="heading-1 text-align-lg-center">
                                    <h3>VEHICLE FINANCE</h3>
                                  </div>
                                  {/* <p className="m-b-lg-20 text-align-lg-center">
                                    Fill in the form below and one of our team
                                    members will get in touch
                                  </p> */}
                                </div>
                                <div>
                                  <div
                                    style={{
                                      marginTop: "2em",
                                      marginBottom: "2em",
                                    }}
                                  >
                                    <Steps current={current} size="small">
                                      <Step title="CRITERIA" />
                                      <Step title="QUALIFY" />
                                      <Step title="PERSONAL" />
                                      <Step title="BANKING" />
                                      <Step title="BUDGET" />
                                      <Step title="DOCUMENTS" />
                                    </Steps>
                                  </div>
                                  <div>
                                    {current === 0 && (
                                      <div
                                        className="m-b-lg-20"
                                        style={{
                                          marginTop: "1.5em",
                                          marginLeft: 0,
                                          height: "27em",
                                        }}
                                      >
                                        <div >
                                          <div className="heading-1" style={{ marginBottom: "1em" }}>
                                            <h3> What you need</h3>
                                            <p className="m-b-lg-0" style={{ fontWeight: 500, marginTop: "0.3em" }}>
                                              🔏 Please be assured that your data is always securely stored and fully protected.
                                            </p>
                                          </div>

                                          <br />
                                        </div>
                                        <div
                                          className="col-sm-6 col-md-6 col-lg-6"
                                          style={{
                                            borderBottom: "None",
                                          }}
                                        >

                                          <span
                                            style={{
                                              display: "flex",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                color: "red",
                                                fontSize: 30,
                                                padding: 0,
                                                marginBottom: 0,
                                              }}
                                            >
                                              1.
                                            </h3>
                                            <h3
                                              style={{
                                                fontSize: 15,
                                                textTransform: "none",
                                                color: "black",
                                                padding: 0,
                                                paddingTop: "0.4em",
                                                paddingLeft: "1em",
                                                lineHeight: "1.3em",
                                              }}
                                            >
                                              ID Document
                                            </h3>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              paddingTop: "1em",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                color: "red",
                                                fontSize: 30,
                                                padding: 0,
                                                marginBottom: 0,
                                              }}
                                            >
                                              2.
                                            </h3>
                                            <h3
                                              style={{
                                                fontSize: 15,
                                                textTransform: "none",
                                                color: "black",
                                                padding: 0,
                                                paddingTop: "0.4em",
                                                paddingLeft: "1em",
                                                lineHeight: "1.3em",
                                              }}
                                            >
                                              Driving Licence
                                            </h3>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              paddingTop: "1em",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                color: "red",
                                                fontSize: 30,
                                                padding: 0,
                                                marginBottom: 0,
                                              }}
                                            >
                                              3.
                                            </h3>
                                            <h3
                                              style={{
                                                fontSize: 15,
                                                textTransform: "none",
                                                color: "black",
                                                padding: 0,
                                                paddingTop: "0.4em",
                                                paddingLeft: "1em",
                                                lineHeight: "1.3em",
                                              }}
                                            >
                                              Latest 3 Months' Bank Statements
                                            </h3>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              paddingTop: "1em",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                color: "red",
                                                fontSize: 30,
                                                padding: 0,
                                                marginBottom: 0,
                                              }}
                                            >
                                              4.
                                            </h3>
                                            <h3
                                              style={{
                                                fontSize: 15,
                                                textTransform: "none",
                                                color: "black",
                                                padding: 0,
                                                paddingTop: "0.4em",
                                                paddingLeft: "1em",
                                                lineHeight: "1.3em",
                                              }}
                                            >
                                              Latest 3 Months' Payslips
                                            </h3>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              paddingTop: "1em",
                                            }}
                                          >
                                            <h3
                                              style={{
                                                color: "red",
                                                fontSize: 30,
                                                padding: 0,
                                                marginBottom: 0,
                                              }}
                                            >
                                              5.
                                            </h3>
                                            <h3
                                              style={{
                                                fontSize: 15,
                                                textTransform: "none",
                                                color: "black",
                                                padding: 0,
                                                paddingTop: "0.4em",
                                                paddingLeft: "1em",
                                                lineHeight: "1.3em",
                                              }}
                                            >
                                              Proof of Address
                                            </h3>
                                          </span>

                                        </div>

                                        <div
                                          className="col-sm-6 col-md-6 col-lg-6"
                                          style={{
                                            textAlign: "right",
                                            paddingTop: "0.6em",
                                            marginLeft: "-4em"
                                          }}
                                        >
                                          <img
                                            width={400}
                                            height={220}
                                            src={`${process.env.PUBLIC_URL}/images/safe-image.png`}
                                            alt="logo"
                                          />
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                          <br />
                                          <div className="heading-1" style={{ marginTop: "1em", marginBottom: "1em", borderTop: "1px solid lightgrey", paddingTop: "0.5em" }}>

                                            <p className="m-b-lg-0" style={{}}>
                                              By proceeding, you authorize the Credit Provider to conduct inquiries into your credit history with any credit reporting agency and to obtain any necessary information required to process your application. You also consent to the Credit Provider sharing information about your payment behavior with credit reporting agencies.
                                            </p>
                                          </div>

                                          <br />
                                        </div>
                                      </div>
                                    )}
                                    {current === 1 && (
                                      <div className="m-b-lg-20">
                                        <div className="heading-1">
                                          <h3>Qualifying Questions</h3>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-6 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Are you applying as an
                                                  individual or a company?
                                                </span>
                                              }
                                              name="applyingEntity"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Individual">
                                                  Individual
                                                </Option>
                                                <Option value="Company">
                                                  Company
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-6 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Does your basic income exceed
                                                  R7 500 per month before
                                                  deductions?
                                                </span>
                                              }
                                              name="applyingIncome"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-6 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Are you a South-African
                                                  citizen?
                                                </span>
                                              }
                                              name="applyingCitizen"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-6 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Are you currently under debt
                                                  review?
                                                </span>
                                              }
                                              name="applyingDebt"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",

                                                  }}
                                                >
                                                  Do you consent to Mega Theron applying for financing on your behalf with all the Credit Providers supported by Mega Theron?
                                                </span>
                                              }
                                              name="applyingConsent"
                                              labelCol={{ span: 24 }}
                                              labelStyle={{ fontSize: "18px" }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Select
                                                style={{ marginTop: "0em" }}
                                                placeholder="Please select"
                                              >
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {current === 2 && (
                                      <span>
                                        <div className="heading-1">
                                          <h3>Personal Details</h3>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="applyFirstName"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  First Name
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="applyLastName"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Last Name
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  ID Number
                                                </span>
                                              }
                                              name="idNumber"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Relationship Status
                                                </span>
                                              }
                                              name="relationshipStatus"
                                              labelCol={{ span: 24 }}
                                              labelStyle={{ fontSize: "18px" }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Single">
                                                  Single
                                                </Option>
                                                <Option value="In a Relationship">
                                                  In a Relationship
                                                </Option>
                                                <Option value="Engaged">
                                                  Engaged
                                                </Option>
                                                <Option value="Married">
                                                  Married
                                                </Option>
                                                <Option value="Divorced">
                                                  Divorced
                                                </Option>
                                                <Option value="Widowed">
                                                  Widowed
                                                </Option>
                                                <Option value="It's Complicated">
                                                  It's Complicated
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Gender
                                                </span>
                                              }
                                              name="sex"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <Radio.Group
                                                onChange={this.onChangeRadioSex}
                                                value={this.state.valueRadioSex}
                                              >
                                                <Space
                                                  direction="horizontal"
                                                  style={
                                                    {
                                                      // display: window.isPhone
                                                      //   ? "grid"
                                                      //   : "",
                                                      // width: "100%",
                                                      // display: "flex",
                                                      // flexDirection: "column",
                                                      // alignItems: "left",
                                                    }
                                                  }
                                                >
                                                  <Radio value="Male">
                                                    Male
                                                  </Radio>
                                                  <Radio value="Female">
                                                    Female
                                                  </Radio>
                                                  <Radio value="Other">
                                                    Other
                                                  </Radio>
                                                </Space>
                                              </Radio.Group>
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="applyEmail"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Your Email Address
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                  type: "email",
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          {/* <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="spouseName"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Spouse Name (If Applicable)
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div> */}
                                        </div>

                                        {/* <div className="row"> */}
                                        {/* <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="weddingDate"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Wedding Date (If Applicable)
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              hasFeedback
                                              style={{
                                                marginTop: "0em",
                                              }}
                                            >
                                              <DatePicker
                                                format={dateFormat}
                                                style={{
                                                  height: 45,
                                                  // border: "1px solid black",
                                                  width: "100%",
                                                }}
                                                value={this.state.selectedDate} // Use state value for DatePicker
                                                onChange={this.handleDateChange} // Update state when date changes
                                              />
                                            </Form.Item>
                                          </div> */}
                                        {/* <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="applyEmail"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Your Email Address
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                  type: "email",
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "0em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div> */}
                                        {/* </div> */}
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="cellNumber"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Cell Number
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                                {
                                                  pattern: /^[0-9]{10}$/,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="workNumber"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Work Number
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  pattern: /^[0-9]{10}$/,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="alternativeNumber"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Alternative Number
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  pattern: /^[0-9]{10}$/,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              name="streetAddressLine"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Address
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Street Address
                                                </span>
                                              }
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        {/* <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              name="streetAddressLine2"
                                              rules={[
                                                { required: false, message: false },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Street Address Line 2
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div> */}
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="city"
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  City
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="stateProvince"
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  State / Province
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>

                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="postalZip"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Postal / Zip Code
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-6 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Period living at address
                                                </span>
                                              }
                                              name="addressPeriod"
                                              labelCol={{ span: 24 }}
                                              labelStyle={{ fontSize: "18px" }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              style={{
                                                marginTop: "0em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Less that a year">
                                                  Less that a year
                                                </Option>
                                                <Option value="1 Year">
                                                  1 Year
                                                </Option>
                                                <Option value="2 Years">
                                                  2 Years
                                                </Option>
                                                <Option value="3 Years">
                                                  3 Years
                                                </Option>
                                                <Option value="4 Years">
                                                  4 Years
                                                </Option>
                                                <Option value="5 Years">
                                                  5 Years
                                                </Option>
                                                <Option value="6 Years">
                                                  6 Years
                                                </Option>
                                                <Option value="7 Years">
                                                  7 Years
                                                </Option>
                                                <Option value="8 Years">
                                                  8 Years
                                                </Option>
                                                <Option value="9 Years">
                                                  9 Years
                                                </Option>
                                                <Option value="10+ Years">
                                                  10+ Years
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Ethnic Group
                                                </span>
                                              }
                                              name="ethnicGroup"
                                              labelCol={{ span: 24 }}
                                              style={
                                                {
                                                  // marginTop: "1em",
                                                }
                                              }
                                            >
                                              <Radio.Group
                                                onChange={this.onChangeRadioEthnicGroup}
                                                value={this.state.valueRadioEthnicGroup}
                                              >
                                                <Space
                                                  direction="vertical"
                                                  style={{
                                                    // display: window.isPhone
                                                    //   ? "grid"
                                                    //   : "",
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "left",
                                                  }}
                                                >
                                                  <Radio value="African">
                                                    African
                                                  </Radio>
                                                  <Radio value="Coloured">
                                                    Coloured
                                                  </Radio>
                                                  <Radio value="Indian">
                                                    Indian
                                                  </Radio>
                                                  <Radio value="White">
                                                    White
                                                  </Radio>
                                                </Space>
                                              </Radio.Group>
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Language Preference
                                                </span>
                                              }
                                              name="languagePreference"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              style={
                                                {
                                                  // marginTop: "1em",
                                                }
                                              }
                                            >
                                              <Radio.Group
                                                onChange={this.onChangeRadioLanguagePreference}
                                                value={this.state.valueRadioLanguagePreference}
                                              >
                                                <Space
                                                  direction="vertical"
                                                  style={{
                                                    // display: window.isPhone
                                                    //   ? "grid"
                                                    //   : "",
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "left",
                                                  }}
                                                >
                                                  <Radio value="English (Primary)">
                                                    English (Primary)
                                                  </Radio>
                                                  <Radio value="Afrikaans">
                                                    Afrikaans
                                                  </Radio>
                                                  <Radio value="Zulu">
                                                    Zulu
                                                  </Radio>
                                                  <Radio value="Sotho">
                                                    Sotho
                                                  </Radio>
                                                </Space>
                                              </Radio.Group>
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div
                                          className="row"
                                          style={{ marginTop: "1em" }}
                                        >
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="nextKin"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Next of Kin
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Name & Surname
                                                </span>
                                              }
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="kinRelationshipStatus"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Relationship
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1.85em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Child">
                                                  Child (Son/Daughter)
                                                </Option>
                                                <Option value="Parent">
                                                  Parent (Father/Mother)
                                                </Option>
                                                <Option value="Sibling">
                                                  Sibling (Brother/Sister)
                                                </Option>
                                                <Option value="Grandparent">
                                                  Grandparent
                                                </Option>
                                                <Option value="Uncle/Aunt">
                                                  Uncle/Aunt
                                                </Option>
                                                <Option value="Nephew/Niece">
                                                  Nephew/Niece
                                                </Option>
                                                <Option value="Cousin">
                                                  Cousin
                                                </Option>
                                                <Option value="Partner (Domestic Partner/Significant Other)">
                                                  Partner (Domestic
                                                  Partner/Significant Other)
                                                </Option>
                                                <Option value="Stepparent">
                                                  Stepparent
                                                </Option>
                                                <Option value="Stepchild ">
                                                  Stepchild
                                                </Option>
                                                <Option value="Legal Guardian">
                                                  Legal Guardian
                                                </Option>
                                                <Option value="Adoptive Parent/Adoptive Child">
                                                  Adoptive Parent/Adoptive Child
                                                </Option>
                                                <Option value="Godparent">
                                                  Godparent
                                                </Option>
                                                <Option value="Godchild">
                                                  Godchild
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="nextKinCellNumber"
                                              // label={
                                              //   <span style={{ fontSize: 13, marginBottom: "-0.5em" }}>
                                              //     Next of Kin Cell Number
                                              //   </span>
                                              // }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                                {
                                                  pattern: /^[0-9]{10}$/,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Please enter a valid phone
                                                  number
                                                </span>
                                              }
                                              style={{
                                                marginTop: "0em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="nextKinAddress"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Address
                                                </span>
                                              }
                                              style={
                                                {
                                                  // marginTop: "1.85em",
                                                }
                                              }
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              name="employerDetails"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Employer Details
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Employer
                                                </span>
                                              }
                                              style={{
                                                marginTop: "0em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              name="employerAddress"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Employer Address
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="salaryDate"
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Salary Date
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput placeholder="ex. 1st of month" />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="tel"
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Tel
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="occupation"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Occupation
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="yearsAtCompany"
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  No. of years at company
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </span>
                                    )}
                                    {current === 3 && (
                                      <span>
                                        <div className="row">
                                          <div className="heading-1" style={{ marginBottom: "1em" }}>
                                            <h3>Banking Details</h3>
                                            <p className="m-b-lg-0" style={{ fontWeight: 500, marginTop: "0.3em" }}>
                                              🔏 Rest assured, your banking details are securely stored and fully protected at all times.
                                            </p>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              name="bankingDetails"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em"
                                                  }}
                                                >
                                                  Banking Details
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Account Holder Name
                                                </span>
                                              }
                                              style={{
                                                marginTop: "0em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              name="bank"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Bank
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <Select placeholder="Please select">
                                                <Option value="Absa Bank">
                                                  Absa Bank
                                                </Option>
                                                <Option value="Standard Bank">
                                                  Standard Bank
                                                </Option>
                                                <Option value="First National Bank (FNB)">
                                                  First National Bank (FNB)
                                                </Option>
                                                <Option value="Nedbank">
                                                  Nedbank
                                                </Option>
                                                <Option value="Capitec Bank">
                                                  Capitec Bank
                                                </Option>
                                                <Option value="African Bank">
                                                  African Bank
                                                </Option>
                                                <Option value="Bidvest Bank">
                                                  Bidvest Bank
                                                </Option>
                                                <Option value="TymeBank">
                                                  TymeBank
                                                </Option>
                                                <Option value="Discovery Bank">
                                                  Discovery Bank
                                                </Option>
                                                <Option value="Sasfin Bank">
                                                  Sasfin Bank
                                                </Option>
                                                <Option value="UBank">
                                                  SUBank
                                                </Option>
                                                <Option value="Grobank">
                                                  Grobank
                                                </Option>
                                                <Option value="Bank of China (South Africa)">
                                                  Bank of China (South Africa)
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="branchCode"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Branch Code
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="accountNumber"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Account Number
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Account Type
                                                </span>
                                              }
                                              name="accountType"
                                              labelCol={{ span: 24 }}
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <Radio.Group
                                                onChange={this.onChangeRadioAccountType}
                                                value={this.state.valueRadioAccountType}
                                              >
                                                <Space
                                                  direction="horizontal"
                                                  style={
                                                    {
                                                      // display: window.isPhone
                                                      //   ? "grid"
                                                      //   : "",
                                                    }
                                                  }
                                                >
                                                  <Radio value="Savings">
                                                    Savings
                                                  </Radio>
                                                  <Radio value="Credit Card">
                                                    Credit Card
                                                  </Radio>
                                                  <Radio value="Transmission">
                                                    Transmission
                                                  </Radio>
                                                  <Radio value="Current">
                                                    Current
                                                  </Radio>
                                                </Space>
                                              </Radio.Group>
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </span>
                                    )}
                                    {current === 4 && (
                                      <span>
                                        {" "}
                                        <div
                                          className="heading-1"
                                          style={{
                                            marginTop: "1em",
                                          }}
                                        >
                                          <h3>Budget</h3>
                                          <p className="m-b-lg-0" style={{ fontWeight: 500, marginTop: "0.3em" }}>
                                            📢 If the amount is not applicable, please enter 0.
                                          </p>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="basicMonthly"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Personal Application Form
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Basic Monthly (Excl Car
                                                  Allowance)
                                                </span>
                                              }
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="totalSalary"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Total Salary (Basic & Car
                                                  Allowance)
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1.9em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="monthlyCommission"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Monthly Commission
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1.9em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="netPay"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Net Take Home Pay
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="otherIncome"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Income Other That Salary /
                                                  Wages
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-6">
                                            <Form.Item
                                              name="sourceOtherIncome"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Source Of Other Income
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              hasFeedback
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <StyledInput />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="bondRepayment"
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    marginBottom: "-0.5em",
                                                  }}
                                                >
                                                  Household Expenses Per Month
                                                </span>
                                              }
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Bond Repayment / Rent
                                                </span>
                                              }
                                              style={{
                                                marginTop: "-1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="creditCard"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Credit Card
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1.9em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="clothingAccount"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Clothing Account
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1.9em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="insurancePayments"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Policy / Insurance Payments
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="ratesWaterElectricity"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Rates, Water & Electricity
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="personalLoanPayments"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Personal Loan Payments
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="furnitureAccounts"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Furniture Accounts
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="overdraftPayments"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Overdraft Payments
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="telephonePayment"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Telephone Payment
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="foodEntertainment"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Food & Entertainment
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                          <div className="col-sm-4 col-md-4 col-lg-4">
                                            <Form.Item
                                              name="transport"
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              help={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    paddingLeft: "0.9em",
                                                    lineHeight: "2.5em",
                                                  }}
                                                >
                                                  Transport
                                                </span>
                                              }
                                              style={{
                                                marginTop: "1em",
                                              }}
                                            >
                                              <InputNumber prefix={<p style={{ marginBottom: "0.5em" }}>R</p>} />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </span>
                                    )}
                                    {current === 5 && (
                                      <span>
                                        {" "}
                                        <div
                                          className="heading-1"
                                          style={{
                                            marginTop: "3em",
                                            textAlign: "center",
                                          }}
                                        >
                                          <h3>Upload Validation Documents</h3>
                                        </div>
                                        <div className="row">
                                          <div

                                            style={{
                                              margin: "auto",
                                              float: "none",
                                            }}
                                          >
                                            <div className="row">
                                              <div className="col-sm-4 col-md-4 col-lg-4">
                                                <Form.Item name="idDocument" rules={[
                                                  {
                                                    required: true,
                                                    message: "ID Document Required",
                                                  },
                                                ]}>
                                                  <Dragger
                                                    fileList={this.state.fileList.idDocument}
                                                    onChange={(info) => this.handleFileChange('idDocument', info)}
                                                    beforeUpload={(file, fileList) => this.beforeUpload('idDocument', file, fileList)}
                                                    multiple={false}
                                                  >
                                                    <p className="ant-upload-drag-icon">
                                                      <InboxOutlined style={{ color: "red" }} />
                                                    </p>
                                                    <p style={{ fontSize: "14px", fontWeight: 500 }} className="ant-upload-text">ID Document</p>
                                                    <p className="ant-upload-hint">Click or drag files to this area</p>
                                                  </Dragger>
                                                </Form.Item>
                                              </div>
                                              <div className="col-sm-4 col-md-4 col-lg-4">
                                                <Form.Item name="drivingLicence" rules={[
                                                  {
                                                    required: true,
                                                    message: "Driving Licence Required",
                                                  },
                                                ]}>
                                                  <Dragger
                                                    fileList={this.state.fileList.drivingLicence}
                                                    onChange={(info) => this.handleFileChange('drivingLicence', info)}
                                                    beforeUpload={(file, fileList) => this.beforeUpload('drivingLicence', file, fileList)}
                                                    multiple={false}
                                                  >
                                                    <p className="ant-upload-drag-icon">
                                                      <InboxOutlined style={{ color: "red" }} />
                                                    </p>
                                                    <p style={{ fontSize: "14px", fontWeight: 500 }} className="ant-upload-text">Driving Licence</p>
                                                    <p className="ant-upload-hint">Click or drag files to this area</p>
                                                  </Dragger>
                                                </Form.Item>
                                              </div>
                                              <div className="col-sm-4 col-md-4 col-lg-4">
                                                <Form.Item name="proofOfAddress" rules={[
                                                  {
                                                    required: true,
                                                    message: "Proof Of Address Required",
                                                  },
                                                ]}>
                                                  <Dragger
                                                    fileList={this.state.fileList.proofOfAddress}
                                                    onChange={(info) => this.handleFileChange('proofOfAddress', info)}
                                                    beforeUpload={(file, fileList) => this.beforeUpload('proofOfAddress', file, fileList)}
                                                    multiple={false}
                                                  >
                                                    <p className="ant-upload-drag-icon">
                                                      <InboxOutlined style={{ color: "red" }} />
                                                    </p>
                                                    <p style={{ fontSize: "14px", fontWeight: 500 }} className="ant-upload-text">Proof of Address</p>
                                                    <p className="ant-upload-hint">Click or drag files to this area</p>
                                                  </Dragger>
                                                </Form.Item>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-sm-4 col-md-4 col-lg-4">
                                                <Form.Item name="payslips" rules={[
                                                  {
                                                    required: true,
                                                    message: "Payslips Required",
                                                  },
                                                ]}>
                                                  <Dragger
                                                    fileList={this.state.fileList.payslips}
                                                    onChange={(info) => this.handleFileChange('payslips', info)}
                                                    beforeUpload={(file, fileList) => this.beforeUpload('payslips', file, fileList)}
                                                    multiple={true}
                                                  >
                                                    <p className="ant-upload-drag-icon">
                                                      <InboxOutlined style={{ color: "red" }} />
                                                    </p>
                                                    <p style={{ fontSize: "14px", fontWeight: 500 }} className="ant-upload-text">Latest 3 Months' Payslips</p>
                                                    <p className="ant-upload-hint">Click or drag files to this area</p>
                                                  </Dragger>
                                                </Form.Item>
                                              </div>
                                              <div className="col-sm-4 col-md-4 col-lg-4">
                                                <Form.Item name="bankStatements" rules={[
                                                  {
                                                    required: true,
                                                    message: "Bank Statements Required",
                                                  },
                                                ]}>
                                                  <Dragger
                                                    fileList={this.state.fileList.bankStatements}
                                                    onChange={(info) => this.handleFileChange('bankStatements', info)}
                                                    beforeUpload={(file, fileList) => this.beforeUpload('bankStatements', file, fileList)}
                                                    multiple={true}
                                                  >
                                                    <p className="ant-upload-drag-icon">
                                                      <InboxOutlined style={{ color: "red" }} />
                                                    </p>
                                                    <p style={{ fontSize: "14px", fontWeight: 500 }} className="ant-upload-text">Latest 3 Months' Bank Statements</p>
                                                    <p className="ant-upload-hint">Click or drag files to this area</p>
                                                  </Dragger>
                                                </Form.Item>
                                              </div>
                                              <div
                                                className="col-sm-4 col-md-4 col-lg-4"
                                                style={{ display: "none" }}
                                              >
                                                <Form.Item
                                                  type="text"
                                                  name="BodyType"
                                                  initialValue={stock.BodyType ? String(stock.BodyType) : ""} // Convert to string if necessary
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                                <Form.Item
                                                  type="text"
                                                  name="Brand"
                                                  initialValue={stock.Brand ? String(stock.Brand) : ""} // Convert to string if necessary
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                                <Form.Item
                                                  type="text"
                                                  name="Model"
                                                  initialValue={stock.Model ? String(stock.Model) : ""} // Convert to string if necessary
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                                <Form.Item
                                                  type="text"
                                                  name="StockNo"
                                                  initialValue={stock.StockNo ? String(stock.StockNo) : ""}
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                                <Form.Item
                                                  type="text"
                                                  name="DealershipName"
                                                  initialValue={stock.DealershipName ? String(stock.DealershipName) : ""}
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                                <Form.Item
                                                  type="text"
                                                  name="DealershipID"
                                                  initialValue={stock.DealershipID ? String(stock.DealershipID) : ""}
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                                <Form.Item
                                                  type="text"
                                                  name="VIN"
                                                  initialValue={stock.VIN ? String(stock.VIN) : ""}
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>

                                                <Form.Item
                                                  type="text"
                                                  name="NewUsed"
                                                  initialValue={stock.NewUsed ? String(stock.NewUsed) : ""}
                                                  rules={[
                                                    { required: false, message: false },
                                                  ]}
                                                  hasFeedback
                                                >
                                                  <StyledInput />
                                                </Form.Item>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="col-sm-12 col-md-12 col-lg-12"
                                            style={{
                                              paddingTop: "1em",
                                              textAlign: "center",
                                              fontFamily: "Roboto",
                                              fontSize: 13,
                                              color: "#00D00073",
                                            }}
                                          >
                                            <p>
                                              These documents will be used once
                                              you got <br />
                                              approved on the pre-opproval
                                            </p>
                                          </div> */}
                                        </div>
                                        <div className="row">
                                          {/* <div className="col-sm-4 col-md-4 col-lg-12">
                                            <Form.Item
                                              rules={[
                                                {
                                                  required: false,
                                                  message: false,
                                                },
                                              ]}
                                              hasFeedback
                                              label={
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                  }}
                                                >
                                                  By proceeding, you authorize the Credit Provider to conduct inquiries into your credit history with any credit reporting agency and to obtain any necessary information required to process your application. You also consent to the Credit Provider sharing information about your payment behavior with credit reporting agencies.
                                                </span>
                                              }
                                              name="authorization"
                                              labelCol={{ span: 24 }}
                                              style={{
                                                marginTop: "3em",
                                                textAlign: "center",
                                              }}
                                            >
                                              <Radio.Group
                                                onChange={this.onChangeRadio}
                                                value={this.state.valueRadio}
                                              >
                                                <Space
                                                  direction="horizontal"
                                                  style={
                                                    {
                                                      // display: window.isPhone
                                                      //   ? "grid"
                                                      //   : "",
                                                    }
                                                  }
                                                >
                                                  <Radio value="YES">YES</Radio>
                                                  <Radio value="NO">NO</Radio>
                                                </Space>
                                              </Radio.Group>
                                            </Form.Item>
                                          </div> */}
                                          <div className="col-lg-12 m-t-lg-12 text-center">
                                            <Form.Item>
                                              <Button
                                                style={{
                                                  border: "1px solid #F91700",
                                                  fontSize: "15px",
                                                  fontWeight: 600,
                                                  borderRadius: "0.3em",
                                                  backgroundColor: "#F91700",
                                                  color: "white",
                                                  height: "55px",
                                                  paddingLeft: "3em",
                                                  paddingRight: "3em",
                                                  textShadow: "none",
                                                  margin: "auto",
                                                  marginTop: "1em",
                                                }}
                                                type="primary"
                                                htmlType="submit"
                                                loading={this.state.loading} // Bind loading state to button
                                                disabled={this.state.loading} // Disable button while loading
                                              >
                                                Submit
                                              </Button>
                                            </Form.Item>
                                            <p
                                              style={{
                                                color: "black",
                                                //marginBottom: "2.5em",
                                                marginTop: "0em",
                                                fontSize: "13px",
                                                lineHeight: "1.5em",
                                              }}
                                            >
                                              By submitting this form, you agree
                                              to our{" "}
                                              <span
                                                style={{
                                                  fontSize: "13px",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                <Link
                                                  style={{ color: "black" }}
                                                  to="/terms"
                                                >
                                                  Terms of Use
                                                </Link>
                                              </span>{" "}
                                              and{" "}
                                              <span
                                                style={{
                                                  fontSize: "13px",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                <Link
                                                  style={{ color: "black" }}
                                                  to="/privacy-policy"
                                                >
                                                  Privacy Statement
                                                </Link>
                                              </span>
                                              .
                                            </p>{" "}
                                          </div>
                                        </div>
                                      </span>
                                    )}
                                  </div>
                                  <div className="steps-action">
                                    {current > 0 && (
                                      <Button
                                        style={{
                                          border: "1px solid black",
                                          fontSize: "15px",
                                          fontWeight: 600,
                                          borderRadius: "0.3em",
                                          backgroundColor: "black",
                                          color: "white",
                                          height: "55px",
                                          paddingLeft: "3em",
                                          paddingRight: "3em",
                                          textShadow: "none",
                                          margin: "auto",
                                          marginTop: "0em",
                                          marginRight: 8,
                                        }}
                                        onClick={() => this.prev()}
                                      >
                                        ← Back
                                      </Button>
                                    )}
                                    {current < 5 && (
                                      <Button
                                        style={{
                                          border: "1px solid #F91700",
                                          fontSize: "15px",
                                          fontWeight: 600,
                                          borderRadius: "0.3em",
                                          backgroundColor: "#F91700",
                                          color: "white",
                                          height: "55px",
                                          paddingLeft: "3em",
                                          paddingRight: "3em",
                                          textShadow: "none",
                                          margin: "auto",
                                          marginTop: "0em",
                                          float: "right"
                                        }}
                                        type="primary"
                                        onClick={this.handleNextClick}
                                      >
                                        Next →
                                      </Button>
                                    )}
                                    {/* {current === 4 && (
                                      <Button type="primary" onClick={() => message.success('Processing complete!')}>
                                        Done
                                      </Button>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </Form>
                  </Modal>
                </div>{" "}
              </div>
            );
          }

          return null;
        })}
      </div>
      // </Spin>
    );
  }
}
function mapStateToProps({ selectedStock }) {
  return { selectedStock };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
})(StockDetails);
